import day from 'dayjs'
import { isEmpty } from '@/utils/common'

const yesOrNoOptions = new Map([
  [true, '是'],
  [false, '否']
])

function floatLabel (options, value) {
  const result = []
  const option = options.find(opt => opt.value === value)
  if (!option) return ''
  result.push(option.label)
  if (option.parentId) result.push(floatLabel(options, option.parentId))
  return result.reverse().join('/')
}

export default {
  // 是否类型
  yesOrNo: (value) => {
    return yesOrNoOptions.get(value) || '-'
  },
  // 值类型
  text: (value) => {
    return value === null || value === undefined || value === '' ? '-' : value
  },
  // 金钱类型
  money: (value) => `￥${value.toFixed(2)}`,
  // 单值匹配
  select: (value, field) => {
    const option = field.options ? field.options.find(option => option.value === value) : null
    return option ? option.label : value || '-'
  },
  // 多值匹配
  multiselect: (value, field) => {
    return !value ? '-' : value.map(data => {
      const item = field.options ? field.options.find(option => option.value === data) : null
      return item ? item.label : ''
    }).join(',')
  },

  /**
   * 时间类型
   */
  time: (value, field) => {
    return value ? day(value).format(field.render.timeType || 'HH:mm:ss') : '-'
  },

  /**
   * 时间类型
   */
  date: (value, field) => {
    return value ? day(value).format(field.render.timeType || 'YYYY-MM-DD') : '-'
  },

  /**
   * 时间类型
   */
  datetime: (value, field) => {
    return value ? day(value).format(field.render.timeType || 'YYYY-MM-DD HH:mm') : '-'
  },
  /* 字符拼接 *
  * @padRender 字符串拼接在字符串前{start}或后{end}
  * @length    拼接长度
  * @charWith  使用拼接的字符串或数字
  *  */
  padString: (value, field) => {
    const { prefix, suffix } = field.render
    return `${prefix || ''}${isEmpty(value) ? '-' : value}${suffix || ''}`
  },
  /**
   * 多级路径拼接
   */
  path: (value, field) => {
    const options = field.options || []
    return floatLabel(options, value) || '-'
  }
}
