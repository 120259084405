import { Message } from 'element-ui'
import router from '@/router'
import { clearAppStorage } from '@/api/auth'

class Interceptor {
  constructor (response) {
    this.messageDuration = 10000
    this.datas = response.data
    this.code = response.data.code
    this.status = response.data.status || response.status
    this.message = (response.data && response.data.message) || response.statusText || '网络连接异常，请检查网络连接'
  }

  validateCode () {
    switch (this.code) {
      // 不同的信息提醒方式采用不同的code区分 0成功 -1失败 6警告
      case 6:
        Message({
          type: 'warning',
          showClose: true,
          duration: this.messageDuration,
          message: this.message
        })
        break
      default:
        Message({
          type: 'error',
          showClose: true,
          duration: this.messageDuration,
          message: this.message
        })
        throw Object({
          response: {
            status: this.code,
            error: true,
            statusText: this.message
          }
        })
    }
  }

  validateStatus () {
    switch (this.status) {
      case 401:
      case 403:
        // 无权限直接跳转login,登录后返回到原先页面
        if (router.currentRoute.name !== 'login') {
          clearAppStorage()
          router.replace({
            path: '/login',
            query: {
              redirect: router.currentRoute.fullPath
            }
          }).catch(e => console.error('router.replace.back-login error:' + e))
        }
        break
      default:
        Message({
          type: 'error',
          showClose: true,
          duration: this.messageDuration,
          message: this.message
        })
        break
    }
  }
}

const interceptorResponse = (response) => {
  const interceptor = new Interceptor(response)
  const { status, data } = response
  const { code } = data
  if (code && code !== 0) {
    // 检查响应结果
    interceptor.validateCode()
    return false
  } else if (status && ![200, 201].includes(status)) {
    // 检查响应状态
    interceptor.validateStatus()
  } else {
    return response
  }
}

export default interceptorResponse
