<template lang="pug">
.num-range-row
  el-input-number(
    v-bind="$attrs"
    v-model="currentValueStart"
    :disabled="leftDisabled || disabled"
    :min="startRange.min"
    :max="startRange.max"
    :controls="!!btnControls"
    :controls-position="btnControls")
  span.connect {{ connect }}
  el-input-number(
    v-bind="$attrs"
    v-model="currentValueEnd"
    :disabled="rightDisabled || disabled"
    :min="endRange.min"
    :max="endRange.max"
    :controls="!!btnControls"
    :controls-position="btnControls")
</template>

<script>
export default {
  name: 'DataformNumRange',
  /**
   * 数值范围输入框
   * @props props参数
   * @prop {Array} value 数值
   * @prop {String} connect 连接符号
   * @prop {Number} min 数值范围输入框, 设置允许的最小值
   * @prop {Number} max 数值范围输入框, 设置允许的最大值
   * @prop {String} triggerType 按钮位置类型, 默认为stack显示符号堆积再右侧
   **/
  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    connect: {
      type: String,
      default: '-'
    },
    min: Number,
    max: Number,
    triggerType: {
      type: String,
      default: 'stack'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    leftDisabled: {
      type: Boolean,
      default: false
    },
    rightDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // minValue: this.min ? this.min : 0,
      // maxValue: this.max ? this.max : 0
    }
  },
  computed: {
    // params () {
    //   return Object.assign({
    //     end: 0,
    //     start: 0
    //   }, this.value)
    // },
    currentValueStart: {
      get () {
        return this.value[0]
      },
      set (val) {
        // this.$emit('input', {
        //   start: val || this.minValue,
        //   end: this.currentValueEnd
        // })
        this.$emit('input', [val, this.currentValueEnd])
      }
    },
    currentValueEnd: {
      get () {
        return this.value[1]
      },
      set (val) {
        // this.$emit('input', {
        //   start: this.currentValueStart,
        //   end: val || this.maxValue
        // })
        this.$emit('input', [this.currentValueStart, val])
      }
    },
    startRange () {
      return {
        min: this.min,
        max: this.currentValueEnd || this.max
      }
    },
    endRange () {
      return {
        min: this.currentValueStart || this.min,
        max: this.max
      }
    },
    btnControls () {
      return this.transformVal(this.triggerType)
    }
  },
  methods: {
    transformVal (val) {
      const _Status = [
        { type: 'normal', value: '-' },
        { type: 'none', value: null },
        { type: 'stack', value: 'right' }
      ]
      const option = _Status.find(item => item.type === val)
      return option ? option.value : val
    }
  }
}
</script>

<style lang="sass" scoped>
.connect
  margin: 0 5px 0 5px
</style>

<style scoped>
.num-range-row >>> .el-input-number {
  width: auto !important;
}
</style>
