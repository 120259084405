export default {
  /**
   * @props props参数
   * @prop {Number} limit 限制上传数量
   * @prop {Array} typeLimit 限制文件上传类型
   * @prop {Boolean} disabled 限制是否置灰
   * @prop {Boolean} multiple 限制是否允许多选
  **/
  data () {
    return {
      limitParams: {
        disabled: this.disabled,
        limit: this.limit,
        multiple: this.multiple,
        accept: this.multiple ? this.typeLimit.join(',') : null
      }
    }
  },
  props: {
    limit: Number,
    multiple: Boolean,
    typeLimit: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {
    filesTypeLimit (file, fileList) {
      const filename = file.name.split('.')
      const fileType = filename[filename.length - 1]
      if (this.typeLimit.length > 0 && !this.typeLimit.includes(`${fileType}`)) {
        this.$message({
          message: `无法上传，当前限制可上传格式为：${this.typeLimit.join(',')}`,
          type: 'warning'
        })
        return false
      } else return true
    },
    fileSizeLimit (file, fileList) {
      // 判断文件是否超过10M，超过则不能上传
      const size = file.size / 1024 / 1024
      if (size >= 10) {
        this.$message({
          message: '上传文件大小不能超过 10MB!',
          type: 'warning'
        })
        return false
      } else return true
    },
    isPasslimit (file, fileList) {
      // 是否通过数量，类型的限制
      return this.filesTypeLimit(file, fileList) && this.fileSizeLimit(file, fileList)
    },
    handleExceed (files, fileList) {
      this.$message({
        message: `当前限制上传文件为： ${this.limit} 个文件`,
        type: 'warning'
      })
    }
  }
}
