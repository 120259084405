<template lang="pug">
el-link(
  v-bind="render"
  type="primary")
    span.iconfont(:class="'el-icon-'+render.icon")
    span(v-html="renderValue")
</template>

<script>
/**
 * @name 渲染-链接
 * @config render配置 Object
 *  @underline 是否下划线 true / false
 *  @disabled 是否禁用状态 false / true
 *  @href 原生href属性
 *  @target 原生target属性
 *  @icon 图标类名
 *  @value 显示值 如无配置则显示value
 */
import isFunction from 'lodash/isFunction'
export default {
  name: 'InfoRenderLink',
  props: {
    value: [Number, String],
    render: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  computed: {
    renderValue () {
      if (isFunction(this.render.value)) {
        return this.render.value(this.value)
      }
      return this.render.value || this.value
    }
  }
}
</script>
