<script>
/**
 * @module DataformItem 表单项
 * @description 通过判断表单项类型决定使用哪种表单项
 * */
import DataFormItemReg from './DataFormItemReg'
export default {
  name: 'DataformItem',
  mixins: [DataFormItemReg],
  /**
  * @property {any} value 表单项数据
  * @property {Object} field 表单项配置
  */
  props: {
    value: [String, Boolean, Array, Number],
    field: Object,
    dot: Boolean,
    labelHidden: Boolean,
    errorToptip: Boolean,
    failFields: {
      type: Object,
      default: () => ({})
    },
    full: Object, // 接收整个表单值
    readOnly: {
      type: Boolean,
      default: false
    }, // 只读
    otherFormItem: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      offset: 8,
      toptipVisable: null
    }
  },
  computed: {
    currentValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    visabled () {
      return this.field.form && this.field.form.visabled !== false
    }
  },
  watch: {
    visabled: {
      handler (flag) {
        this.$emit('onChange', this.field, flag ? this.value : null)
      }
    },
    currentValue (val, oldVal) {
      if (val == null && oldVal == null) return
      if (String(oldVal) !== String(val)) {
        this.$emit('onValueChange', this.field.name, val, oldVal)
      }
    },
    failFields: {
      immediate: true,
      deep: true,
      handler (fails) {
        this.toptipVisable = !!fails[this.field.name] && this.errorToptip
      }
    }
  },
  render (h) {
    if (!this.visabled) return null
    const { name, label, form } = this.field
    return (
      <el-form-item
        key={`formitem_${name}`}
        ref={`formitem_${name}`}
        props={this.$attrs.formItemProp}
        on={this.$listeners}
        label={label}
        prop={name}
        label-hidden={this.labelHidden || this.field.labelHidden}
        is-toptiperror={this.errorToptip}>
        <template slot='label'>
          <span>{label}{this.dot || form.dot ? ':' : ''}</span>
          {this.field.labelTip
            ? (
              <el-popover
                placement="top-start"
                trigger="hover"
                width="200"
                content={this.field.labelTip}>
                <i
                  slot="reference"
                  style="padding-left: 4px;cursor: pointer"
                  class="el-icon-warning-outline"></i>
              </el-popover>
            )
            : ''}
        </template>
        {
          form.preSlotRender && typeof form.preSlotRender === 'function'
            ? form.preSlotRender(h) : ''
        }
        <el-tooltip
          class="form-item_component"
          effect="dark"
          tabindex={null}
          placement="top-start"
          value={this.toptipVisable}
          disabled={!this.toptipVisable}
          content={this.failFields[name]}>
          {
            h(!this.readOnly ? this.dataFormItem[form.tag] || this.otherFormItem[form.tag] : 'DataformText', {
              // vModel: this.currentValue,
              props: {
                field: this.field,
                value: this.currentValue,
                data: this.full,
                ...this.field.form
              },
              key: this.field.name,
              attrs: { ...this.field.form },
              style: form.inputWidth ? { width: form.inputWidth + ' !important' } : {},
              on: {
                input: (value) => { this.currentValue = value },
                ...this.$listeners
              }
            })
          }
        </el-tooltip>
        {
          form.suffixSlotRender && typeof form.suffixSlotRender === 'function'
            ? form.suffixSlotRender(h) : ''
        }
      </el-form-item>
    )
  }
}
</script>
<style lang="sass" scoped>
.el-form-item[is-toptiperror]
  position: relative
  ::v-deep .el-form-item__error
    display: none
.form-item_component[bottomtip]::after
  content: attr(bottomtip)
  color: rgba(0,0,0,.45)
  font-size: .8rem
</style>
