<template lang="pug">
el-form.wf-dataform(
  v-bind="$attrs"
  v-maxHeight="inline && isCollapse"
  :class="{'fixed-box': buttonFixed}"
  :key="formkey"
  :ref="refname"
  :model="formData"
  :rules="rules"
  :inline="inline"
  :collapsable="isCollapse"
  :label-width="labelWidthVal"
  :label-position="labelPosition"
  :is-fixedbtn="buttonFixed"
  :validate-on-rule-change="false"
  @validate="recodeValidate")
  slot
    FormBody.wf-dataform__body(
      ref="formBody"
      v-bind="$attrs"
      :formData="formData"
      :columns="fieldColumns"
      :formFields="formFields"
      :colspanNum="colspanNum"
      :inline="inline"
      :type="type"
      :maxRow="maxRow"
      :dot="dot"
      :labelPosition="labelPosition"
      :collapsable="isCollapse"
      :failFields="validateFailFields"
      @onChange="onChangeField")
      template(#bodyHeader)
        slot(name="bodyHeader")
      template(#bodyFooter)
        slot(name="bodyFooter")
  el-form-item.dataform-button-item(
    v-if="formTempBtns.length"
    :is-button="true"
    :class="{ 'nobutton': formButtonList.length === 0, 'button-align__fixed': buttonFixed }"
    :is-multi="isMultiBtns"
    :data-group-num="formBtnsKey.length"
    :label-width="type && type !== 'default' ? '0px' : labelWidthVal")
    ButtonGroup.dataform-button__inline(
      v-if="inline"
      formName="dataform"
      :buttonList="formButtonList"
      :formValidate="formValidate")
    ButtonGroup.dataform-button(
      v-else
      v-for="(position, index) in formBtnsKey"
      :key="position"
      :class="`button-align__${position}`"
      :position="position"
      :formName="`groupForm_${position}`"
      :buttonList="formButtonList[position]"
      :formValidate="formValidate")
</template>

<script>
/**
 * @module Dataform 传统表单
 * @description 除了el-form的常规属性，还支持以下：
 * buttonFixed时,需要父组件设置relative
 * */
import ButtonGroup from '../ButtonGroup'
import DataformItem from './DataformItem'
import FormBody from './FormBody'
import formCommon from './mixins/formCommon.js'
import inline from './mixins/inline.js'
import pickBy from 'lodash/pickBy'
export default {
  name: 'Dataform',
  mixins: [formCommon, inline],
  components: {
    ButtonGroup,
    DataformItem,
    FormBody
  },
  props: {
    type: {
      type: String,
      validator: (value) => {
        return ['row', 'grid', 'inline'].indexOf(value) > -1
      }
    },
    dot: {
      type: Boolean,
      default: false
    },
    collapsable: {
      type: Boolean,
      default: false
    },
    buttonAlign: {
      type: String,
      default: 'left',
      validator: (value) => {
        return ['center', 'left', 'right'].indexOf(value) > -1
      }
    },
    maxRow: Number
  },
  data () {
    return {
      refname: 'dataform',
      formTempBtns: this.buttonList,
      validateFailFields: {},
      slotRender: {}
    }
  },
  filters: {
    transBtnStyle (align) {
      return {
        textAlign: align
      }
    }
  },
  computed: {
    isMultiBtns () {
      return !this.inline
    },
    formButtonList: {
      get () {
        if (this.inline) {
          return this.formTempBtns.map(button => ({
            ...button,
            formData: this.formData
          })).concat(
            this.collapsable ? this.privateCollapseBtn : []
          )
        }
        return this.formTempBtns.reduce((res, btn) => {
          const button = {
            ...btn,
            formData: this.formData
          }
          const position = btn.position || this.buttonAlign // 传统按钮组默认居左
          res[position] = [...res[position], button]
          return res
        }, { left: [], center: [], right: [] })
      },
      set (value) {
        this.formTempBtns = value
      }
    },
    formBtnsKey () {
      return Object.keys(pickBy(this.formButtonList, (arr) => { return arr.length > 0 }))
    }
  },
  methods: {
    recodeValidate (fieldName, flag, message) {
      if (!flag) {
        this.$set(this.validateFailFields, fieldName, message)
      } else if (flag && Object.hasOwnProperty.call(this.validateFailFields, fieldName)) {
        this.$set(this.validateFailFields, fieldName, null)
        delete this.validateFailFields[fieldName]
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.dataform-button-item.nobutton
  display: none
.dataform-button-item
  margin-bottom: 0
.fixed-box
  width: 100%
  height: 100%
  .wf-dataform__content
    height: calc(100% - 71px)
.form-upload
  text-align: left
.wf-dataform__body:not([inline])
  margin: 24px 0
.wf-dataform.el-form--inline
  display: flex
  justify-content: space-between
  // 使用errorTopTip的属性不需要margin-bottom
  &[errortoptip]
    ::v-deep .el-form-item
      margin-bottom: 8px
  ::v-deep .form-body__container
    flex: 1
  .dataform-button-item
    ::v-deep
      .el-button--text[label="收起"]
        svg
          transform: rotateX(-180deg)
    // max-width: 20%
  .form-body__container[collapsable="true"]
    overflow: hidden
.wf-dataform[is-fixedbtn]
  display: flex
  flex-direction: column
  // height: 100%
  overflow: hidden
  .wf-dataform__body
    flex: 1
    overflow: auto
.dataform-button-item
  &+.dataform-button-item
    padding-left: 24px
  &[is-multi] ::v-deep .el-form-item__content
    display: grid
    // grid-template-columns: 0px repeat(attr(data-group-num), 1fr)
  &[data-group-num='2'] ::v-deep .el-form-item__content
    grid-template-columns: 0px repeat(2, 1fr)
  &[data-group-num='3'] ::v-deep .el-form-item__content
    grid-template-columns: 0px repeat(3, 1fr)
  // .button-align__fixed
  //   ::v-deep .el-form-item__content
  //     display: flex
  //     justify-content: space-between
</style>
<style scoped>
.wf-dataform >>> .el-form-item__label {
  overflow-wrap: break-word
}
/* .dataform-button-item[is-multi] >>> .el-form-item__content {
  display: grid;
  grid-template-columns: 0px repeat(attr(data-group-num), 1fr)
} */
</style>
