<template lang="pug">
div
  component(
    :is="isFixed ? 'el-time-select' : 'el-time-picker'"
    value-format="timestamp"
    v-bind="$attrs"
    v-on="$listeners"
    v-model="currentValue"
    :is-range="false"
    :prefix-icon="prefixIcon"
    :clear-icon="clearIcon")
</template>

<script>
/**
 * @module DataformTime 时间选择器
 *   */
import iconMixin from './iconMixin.js'
export default {
  name: 'DataformTime',
  mixins: [iconMixin],
  /**
   * 时间选择器
   * @props props参数
   * @prop {String, Array, Number} value 值
   * @prop {Boolean} isFixed 是否为固定时间选择器
   * @prop {String} dataFormat 获取后端的值以及提交给后端的值的格式
  **/
  data () {
    return {
      DEFAULT_PREFIX_ICON: 'el-icon-time'
    }
  },
  props: {
    value: [String, Array, Number],
    isFixed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentValue: {
      get () {
        return this.formatValue(this.value)
      },
      set (val) {
        this.$emit('input', this.formatValue(val))
      }
    }
  },
  methods: {
    formatValue (val) {
      if (!val) return ''
      if (this.isFixed) return val
      // if (this.isRange) return val.map(item => this.convertFormat(item))
      else return this.convertFormat(val)
    },
    convertFormat (val) {
      // 转换格式
      return val.valueOf()
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
