import { pick } from 'lodash'
import { getRelation, getRelationByFilter } from '@/api'

const state = {
  relation: {}
}

const mutations = {
  SET_RELATION: (state, newRelation) => {
    state.relation = {
      ...state.relation,
      ...newRelation
    }
  },
  CLEAR_RELATION: (state) => {
    state.relation = {}
  }
}
/**
 * relation字典请求，
 * relation请求的参数格式：{relation: [{name: relationA, 后续扩展...}]}
 * @param {array} relation ['relationA', 'relationB']
 * */
const getRelationRequest = (relations) => {
  return new Promise((resolve, reject) => {
    getRelation(relations)
      .then(response => {
        if (response.data.meterStatus) {
          // 补充表况正常值字典
          response.data.meterStatus.splice(0, 0, {
            enable: true,
            label: '正常',
            value: 1
          })
        }
        resolve(response.data)
      })
      .catch(reject)
  })
}

/**
 * relation字典带搜索请求
 * @param {array} relation
 * [{
    name: 'stepDefineByFilterLast', // relation名称
    filterBy: [{
      'name': 'flowDefineId',
      'type': 'eq',
      'value': this.$route.query.id
    }] // filter内容
  }]
 * */
const getRelationFilterRequest = (relations) => {
  return new Promise((resolve, reject) => {
    getRelationByFilter(relations)
      .then(response => {
        const result = response.data.data.reduce((r, item) => {
          r[item.name] = item.data
          return r
        }, {})
        if (result.meterStatusv2) {
          // 补充表况正常值字典
          result.meterStatusv2.splice(0, 0, {
            enable: true,
            label: '正常',
            value: 1
          })
        }
        resolve(result)
      })
      .catch(reject)
  })
}

const actions = {
  /**
   * @param {array} relation ['relationA', 'relationB']
  */
  getRelations: async ({ commit, state, dispatch }, relationList) => {
    try {
      // 实时通过接口获取的relation: meterStatus表况 roles角色 price价格方案 usenature用水性质 collections收款账户
      const realTimeRelation = ['meterStatus', 'roles', 'price', 'usenature', 'collections', 'orgs']
      // 把新的relation过滤出来
      const newRelationList = relationList.filter(value => realTimeRelation.includes(value) || !state.relation[value])
      // 向后端拿到新的relation然后更新到store里
      if (newRelationList.length) {
        const newRelation = await getRelationRequest(newRelationList)
        commit('SET_RELATION', newRelation)
      }
      // 筛选出功能需要的relation然后返回
      return pick(state.relation, relationList)
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * relation字典带搜索请求
   * @param {array} relation
   * [{
      name: 'stepDefineByFilterLast', // relation名称
      filterBy: [{
        'name': 'flowDefineId',
        'type': 'eq',
        'value': this.$route.query.id
      }] // filter内容
    }]
   * */
  getDyncRelation: async ({ commit, state, dispatch }, relationList) => {
    try {
      const newRelation = await getRelationFilterRequest(relationList)
      // 筛选出功能需要的relation然后返回
      return newRelation
    } catch (error) {
      console.error(error)
    }
  },
  getSingleOptions: async ({ commit, state, dispatch }, relationName) => {
    const relationList = await dispatch('getRelation', [relationName])
    return relationList[relationName].data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
