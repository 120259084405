import Vue from 'vue'
// import AppFrame from '@/views/App/AppFrame'
import App from '@/views/App'
import './assets/font/iconfont.css'
import 'qweather-icons/font/qweather-icons.css'
import './plugins/component.js'

import router from './router'
import store from './store'
import Event from '@/utils/event'
import { renderRelationColumns, getPageActions } from '@/utils/common'
import { checkActions } from '@/api/auth'

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.prototype.$renderRelationColumns = renderRelationColumns
Vue.prototype.$checkActions = checkActions
Vue.prototype.$getPageActions = getPageActions

// 全局通信方法
window.globalEvent = new Event()

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
  // render: h => h(AppFrame)
}).$mount('#app')
