// 表格单选、多选
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'

export default {
  props: {
    // 行数据的key, 开启单选、多选、树表格时必填，否则表格数据改变时，行高亮/已勾选样式可能会错乱
    rowKey: {
      type: String,
      default: 'id'
    },
    // 多选开关
    isSelection: {
      type: Boolean,
      default: false
    },
    // 数据更新之后保留之前选中的数据开关
    reserveSelection: {
      type: Boolean,
      default: true
    },
    // 单选-高亮当前选中行
    highlightClickRow: {
      type: Boolean,
      default: false
    },
    // 变灰不可选择行
    grayNonselectableRow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // 多选表格选中数据
      selectedTableDatas: [],
      selectedTableDataKeys: [],
      // 单选表格选中数据
      currentRow: {}
    }
  },
  computed: {
    // 已勾选数
    selectedNum () {
      return this.selectedTableDatas.length
    }
  },
  methods: {
    rowClassName ({ row, index }) {
      // 单选/多选时，不可选择行变灰
      const { selectable } = this.$refs.table.$attrs
      if (this.grayNonselectableRow && selectable) return selectable(row, index) ? '' : 'table-nonselectable-row'
    },
    // 多选表格-清空所有已选中数据
    clearSelection () {
      this.$refs.table.clearSelection()
    },
    // 多选表格-勾选/取消勾选数据时触发该事件
    getSelectRow (rows, row) {
      this.$emit('selectRow', rows, row)
      const index = rows.findIndex(item => item === row)
      index === -1 ? this.$emit('removeSelect', rows, row) : this.$emit('addSelect', rows, row, index)
      // this.$nextTick(() => { // TODO 树表格多选
      //   if (this.isTree) this.treeToggleEvent(rows, row)
      // })
    },
    // 多选表格-勾选数据发生改变时触发该事件 TODO 树表格事件
    selectionChange (rows) {
      this.selectedTableDatas = cloneDeep(rows)
      this.selectedTableDataKeys = rows.map(item => item[this.rowKey])
      this.$emit('selectionChange', rows)
    },
    // 多选表格-点击全选框时触发该事件
    selectAll (rows) {
      this.$emit('selectAll', rows)
      // if (!this.isTree) return // TODO 树表格多选
      // this.$nextTick(() => this.treeSelectAllEvent(rows))
    },
    /**
     * @name 多选表格-改变数据选中状态
     * @param {Array} row 需要改变选中状态的数据
     * @param {Boolean} selected 选中状态
     */
    toggleRowSelection (rows = [], selected = true) {
      this.$nextTick(() => rows.map(row => this.$refs.table.toggleRowSelection(row, selected)))
    },
    // 单选表格-表格的当前行发生变化的时候会触发该事件
    currentChange (curRow, oldRow) {
      this.currentRow = curRow
      this.$emit('currentChange', curRow, oldRow)
    },
    /**
     * @name 单选表格-改变当前选中状态
     * @param {Object} curRow 需要选中的行数据（若无传选中行数据，则是取消选中已选择数据）
     */
    setCurrentRow (curRow) {
      if (!curRow) this.$refs.table.setCurrentRow()
      const findResult = find(this.toberenderTableData, curRow)
      if (findResult) this.$refs.table.setCurrentRow(curRow)
    },
    /**
     * @name 单选表格-改变当前选中状态
     * @param {Object} rowKeyValue 以rowKey作为主键,rowKeyValue作为指找到行并设置高亮
     */
    setCurrentRowByKey (rowKeyValue) {
      if (!rowKeyValue) this.$refs.table.setCurrentRow()
      const findResult = this.toberenderTableData.find(row => row[this.rowKey] === rowKeyValue)
      if (findResult) this.$refs.table.setCurrentRow(findResult)
    }
  }
}
