<template lang="pug">
.datatable-main
  slot(name="header")
    .datatablepage-header(:class="{'filter-margin': needFilterMargin}")
      slot(name="filter")
        .datatablepage-filter-wrapper(
          v-if="filterFields.length && showFilter"
          :class="{'filter-button': !renderToolbarButtonList.length}")
          DataForm.datatablepage-filter(
            ref="filterForm"
            type="inline"
            :class="{'algin-button': !filterFormCollapsable}"
            :collapsable="filterFormCollapsable"
            :value="filterBy"
            :formFields="filterFields|filterFormFields"
            :buttonList="filterButtonList"
            @collapseClick="filterFormCollapse")
  .datatable-table-wrapper
    slot(name="prep")
      .datatablepage-prep
        slot(name="filterInfo")
          .datatablepage-filter-info
            span(v-show="filterByArray.length") 筛选：
            el-tag(
              v-for="filter in filterByArray"
              :key="`filter-${filter.key}`"
              size="small"
              type="info"
              effect="plain"
              closable
              @close="filterFormRemove(filter.key)") {{filter.value | renderTag(filterFieldsObject[filter.key].form)}}
        slot(name="toolbar")
          .datatablepage-toolbar(v-if="renderToolbarButtonList.length")
            ButtonGroup(:buttonList="renderToolbarButtonList")
        slot(name="toolbarDivider")
          .datatablepage-prep-hack(v-if="renderToolbarButtonList.length && renderSettingButtonList.length")
            el-divider(direction="vertical")
        slot(name="setting")
          .datatablepage-setting(v-if="renderSettingButtonList.length")
            ButtonGroup(
              :class="{'setting-buttons': !renderToolbarButtonList.length}"
              :buttonList="renderSettingButtonList")
    el-table.datatable-table(
      ref="table"
      v-bind="$attrs"
      v-on="$listeners"
      v-loading="loading"
      :height="1"
      border
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.2)"
      :id="keyName"
      :row-key="rowKey"
      :data="resultrenderTableData"
      :size="size"
      :empty-text="loading? '' : emptyText"
      :row-class-name="rowClassName"
      :cell-class-name="cellClassName"
      :highlight-current-row="highlightClickRow"
      :load="lazyTreeLoad"
      :tree-props="{children: childrenKey, hasChildren: hasChildrenKey}"
      @select="getSelectRow"
      @select-all="selectAll"
      @selection-change="selectionChange"
      @current-change="currentChange"
      @row-click="$emit('rowClick', $event)"
      @row-dblclick="$emit('rowDblClick', $event)"
      @sort-change="sortChange")
      el-table-column(
        v-if="isSelection"
        class-name="selectBox"
        type="selection"
        width="35"
        row-key="id"
        :reserve-selection="reserveSelection"
        :selectable="$attrs.selectable")
      slot(name="left")
      el-table-column(
        v-if="showIndex"
        type="index"
        width="50"
        :label="indexColumnName"
        :index="columnIndex")
      TableColumn(
        v-for="name in columnList"
        v-if="visibleFilter(name)"
        :key="name"
        :name="name"
        :columns="columns"
        :fixedLeft="fixedLeft"
        :fixedRight="fixedRight")
        template(:slot="name", slot-scope="scope")
          slot(:name="name", :column="scope.column")
      slot
      el-table-column(
        v-if="renderOperateButtonList.length"
        label="操作"
        fixed="right"
        :width="operationWidth")
        template(slot="header",slot-scope="scope")
          slot(name="operate")
            span 操作
        template(slot-scope="scope")
          .datatablepage-operate
            ButtonGroup(
              :needDivider="true"
              :buttonList="renderOperateButtonList"
              :data="scope.row")
      slot(name="right")
    slot(name="footer")
      .datatablepage-footer(v-if="hasFooter")
        slot(name="info")
          .datatablepage-info
            .selected-info(v-if="isSelection") 已选择#[strong.select-num {{selectedNum}}]项
        slot(name="pagination")
          el-pagination.datatablepage-pagination(
            v-if="hasPage"
            background
            :pager-count="pagerCount"
            @size-change="pageSizeChange"
            @current-change="pageIndexChange"
            :current-page.sync="pageIndex"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="toberenderTotal")
  ColumnFilter(
    v-if="showColumnFilter"
    v-model="showColumnFilter"
    :columns="columns"
    :columnList="columnList"
    :fixedLeft="fixedLeft"
    :fixedRight="fixedRight"
    :visibleList="visibleList"
    @columnDate="getColumnFilterDate"
    @close="closeColumnFilter")
</template>
<script>
import resize from 'vue-resize-directive'
import slice from 'lodash/slice'
import orderBy from 'lodash/orderBy'

import TableColumn from './TableColumn'
import ColumnFilter from './columnFilter'

import filterForm from './plugins/filterForm'
import getTableData from './plugins/getTableData'
import fixed from './plugins/fixed'
import buttonList from './plugins/buttonList'
import filterByStatic from './plugins/filterByStatic.js'
import select from './plugins/select.js'
import bigData from './plugins/bigData.js'
import { tagRenderType, tagRenderList } from './plugins/tagRender'
import tree from './plugins/tree.js'
import { treeFormat } from '@/utils/tree.js'

export default {
  name: 'Datatable',
  mixins: [filterForm, getTableData, fixed, buttonList, select, bigData, tree],
  components: { ColumnFilter, TableColumn },
  directives: {
    resize
  },
  props: {
    // 作为表格关键key
    keyName: {
      type: String,
      default: 'wfTable'
    },
    // 外部传入的数据
    data: {
      type: Array
    },
    // 数据接口
    resource: {
      type: String
    },
    // 是否使用服务端分页
    serverSide: {
      type: Boolean,
      default: false
    },
    // 列设置，列的数据渲染
    columns: {
      type: Array,
      default: () => ([])
    },
    // 一次性加载去掉分页
    hasPage: {
      type: Boolean,
      default: true
    },
    hasFooter: {
      type: Boolean,
      default: true
    },
    // 显示搜索栏
    showFilter: {
      type: Boolean,
      default: true
    },
    // 每页记录数选项
    pageSizes: {
      type: Array,
      default: () => ([30, 50, 100, 200, 500])
    },
    // 页码按钮的数量，当总页数超过该值时会折叠，只接收单数！
    pagerCount: {
      type: Number,
      default: 5
    },
    // 默认每页记录数，初始化时用
    defaultPageSize: {
      type: Number
    },
    // 默认页码，初始化时用
    defaultpageIndex: {
      type: Number,
      default: 1
    },
    // 默认排序，初始化时用
    defaultSortBy: {
      type: Array,
      default: () => ([])
    },
    // 表格数据为空时提示
    emptyText: {
      type: String,
      default: '暂无数据'
    },
    // 展示序号列
    showIndex: {
      type: Boolean,
      default: false
    },
    // 序号列的列名
    indexColumnName: {
      type: String,
      default: '序号'
    },
    // 是否需要搜索栏边距
    needFilterMargin: {
      type: Boolean,
      default: true
    },
    // 列表不搜索默认为空
    defaultEmpty: {
      type: Boolean,
      default: false
    },
    cellClassNameFunc: Function
  },
  filters: {
    renderTag (value, fileId) {
      return tagRenderList[tagRenderType(fileId)](value, fileId)
    },
    filterFormFields (fields) {
      return fields.filter(item => item.showable !== false)
    }
  },
  data () {
    return {
      // 通过接口读取到的数据
      tableData: [],
      // 通过接口读取到或者从data获取到的数据，存储在表格里给渲染前计算
      allTableData: [],
      // 每页记录数
      pageSize: this.defaultPageSize || this.pageSizes[0],
      // 页码
      pageIndex: this.defaultpageIndex,
      // 排序
      sortBy: this.defaultSortBy,
      // 合计数
      total: 0,
      // loading状态
      loading: false,
      cellClassName: (params) => {
        const classNames = this.cellClassNameFunc ? this.cellClassNameFunc(params) : []
        params.column.label === '操作' && classNames.push('operation-column')
        return classNames.join(' ')
      }
    }
  },
  computed: {
    // 渲染的data，如果serverSide为true，直接读tableData，如果不是读pagedTableData
    toberenderTableData () {
      const renderTableData = this.serverSide ? this.tableData : this.pagedTableData
      return !this.isTree ? renderTableData : treeFormat(
        renderTableData,
        this.rowKey,
        this.parentKey,
        this.childrenKey
      )
    },
    // 如果serverSide为true，直接读total，如果不是读filteredRecords
    toberenderTotal () {
      return this.serverSide ? this.total : this.filteredRecords
    },
    // 已分页的数据，pageIndex或pageSize或sortedTableData变动时变动
    pagedTableData () {
      const end = this.pageIndex * this.pageSize
      return this.hasPage ? slice(this.sortedTableData, end - this.pageSize, end) : this.sortedTableData
    },
    // 已排序的数据，sortBy或filteredTableData变动时变动
    sortedTableData () {
      if (this.sortBy.length) {
        const props = this.sortBy.map(({ prop }) => prop)
        const orders = this.sortBy.map(({ order }) => order.substring(0, order.length - 6))
        return orderBy(this.filteredTableData, props, orders)
      } else {
        return this.filteredTableData
      }
    },
    // 已过滤的数据，(allTableData或filterBy或filterFieldsObject变动时变动)
    filteredTableData () {
      return filterByStatic({
        datas: this.allTableData,
        filterBy: this.filterBy,
        columns: this.filterFieldsObject
      })
    },
    // 过滤完的数量，filteredTableData变动时变动
    filteredRecords () {
      return this.filteredTableData.length
    }
  },
  mounted () {
    this.init()
    // 初始化后，需要对表格重新计算布局，否则右固定列会挡住前面一列的右边框
    this.tableDoLayout()
    // 监听滚动事件
    this.scrollListener()
    if (this.$refs.filterForm) {
      this.updateFormCollaspable()
    }
  },
  watch: {
    size () {
      // 解决改变表格行高，存在固定列，垂直滚动条从有变无/从无变有时，固定列错位问题
      this.tableDoLayout()
    },
    // 监听表格渲染数据变化
    toberenderTableData () {
      this.watchRenderTableDataChange()
    },
    // pageSize、sortBy或filterBy变化时pageIndex设置为1，同时如果serverSide为true，getTableData
    pageSize () {
      this.watchPageChange()
    },
    sortBy () {
      this.watchPageChange()
    }
  },
  methods: {
    init () {
      // 判断是否有传入数据，如果有，将页面的allTableData设置为传入数据
      if (this.data) {
        this.updateAllTableData()
      }
      // 判断页面加载时是否不要加载数据，如果不要第一时间加载数据，则跳出（待处理）

      // 判断页面是否绑定接口或者默认不搜索，则跳出
      if (!this.resource || this.defaultEmpty) return
      // 判断是否服务端分页，如果是，则是则按页面状态获取数据，如果不是则getTableData时获取资源所有数据并传进allTableData
      if (this.serverSide) {
        this.getTableData()
      } else {
        this.getAllTableData()
      }
    },
    updateAllTableData (data) {
      this.$set(this, 'allTableData', data || this.data)
    },
    // 序号事件
    columnIndex (index) {
      return index + this.pageIndex * this.pageSize - this.pageSize + 1 + this.renderStartRowIndex
    },
    // 监视表格数据变化
    watchRenderTableDataChange () {
      // 表格数据发生改变时，重新高亮currentRow
      if (Object.keys(this.currentRow || {}).length) this.setCurrentRow(this.currentRow)
      // 树状表格的数据在发生变化（刷新、重新请求数据）后，须清空已选数据，然后重新勾选
      this.$nextTick(() => {
        if (this.isTree && this.selectedTableDatas.length) this.treeReselect()
      })
    },
    // 监视页面变化，方便watch调用
    watchPageChange () {
      // pageSize、sortBy变化时pageIndex设置为1
      this.pageIndex = 1
      // 同时如果serverSide为true，getTableData
      if (this.serverSide) {
        this.getTableData()
      } else if (this.resource) {
        this.getAllTableData()
      }
    },
    // 改变页码
    pageIndexChange (val) {
      // 改变页码
      this.pageIndex = val
      // 同时如果serverSide为true，getTableData
      if (this.serverSide) {
        this.getTableData()
      } else if (this.resource) {
        this.getAllTableData()
      }
    },
    // 改变当页显示条目数
    pageSizeChange (val) {
      this.pageSize = val
    },
    // 改变排序数据
    sortChange (sortBy) {
      const tmp = this.sortBy.filter(({ prop }) => prop !== sortBy.prop)
      this.sortBy = sortBy.order ? [sortBy, ...tmp] : tmp
    },
    // 表格宽高计算
    tableDoLayout () {
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    filterFormCollapse (isCollapse) {
      if (!isCollapse) this.tableDoLayout()
    },
    updateFormCollaspable () {
      const filterFormBody = this.$refs.filterForm.$el.getElementsByClassName('dataform-body__default')[0]
      this.filterFormCollapsable = !!filterFormBody && filterFormBody.clientHeight > 60
    }
  }
}
</script>
<style lang="sass" scoped>
.datatable-main
  display: flex
  flex: 1
  flex-direction: column
  overflow: hidden
  height: 100%
  width: 100%
.datatable-table-wrapper
  display: flex
  flex: 1
  flex-direction: column
  padding: 8px
  background-color: #fff
  height: 100%
  box-sizing: border-box
  overflow: hidden
  .datatable-table
    // display: flex
    // flex: 1
    // flex-direction: column
    width: 100%
    ::v-deep
      .el-table__body-wrapper
        height: 100%
        overflow-y: overlay
      .table-nonselectable-row
        background-color: $--table-row-unclick-background-color !important
        cursor: not-allowed
        pointer-events: none
  ::v-deep
    .el-table--enable-row-hover
      .el-table__body
        tr:hover
          td
            cursor: pointer
.datatablepage-header
  &.filter-margin>div
    margin-bottom: 1em
  .datatablepage-filter-wrapper
    background: #fff
    padding: 8px
    &.filter-button
      ::v-deep .el-form-item.dataform-button-item
        min-width: unset !important
        padding: unset
  .datatablepage-filter
    // margin-bottom: -18px
    display: flex
    justify-content: space-between
    border-radius: 4px
    &.algin-button
      ::v-deep
        .el-form-item.dataform-button-item
          margin-right: 41px
    ::v-deep .el-form-item.dataform-button-item
      margin-right: 0
      // min-width: 133px
  .datatablepage-toolbar
    margin-right: 1em
.datatablepage-footer
  font-size: $--th-font-size
  &>div
    flex: 1
    margin-top: 1em
  .datatablepage-info
    display: inline-block
    white-space: nowrap
    line-height: 32px
    .select-num
      margin: 0 5px
      color: $--color-primary
.datatablepage-pagination
  text-align: right
  float: right
  &::after
    display: block
    content: ''
    height: 0
    visibility: hidden
    clear: both
.datatable-table
  ::v-deep
    tr.current-row
      td
        background-color: #4b89ff33
        font-weight: 600
    .table-handle
      font-size: 22px
      cursor: move
    // 拖拉排序或拖拉改变父子关系时的样式
    .highlight-in
      background: #ecf5ff
    .highlight-up
      box-shadow: 0 10px 18px -20px inset blue
      &::after
        transform: translate(0, -100%)
        box-shadow: 0 -10px 18px -20px inset blue
    .highlight-down
      box-shadow: 0 -10px 18px -20px inset blue
      &::after
        transform: translate(0, 100%)
        box-shadow: 0 10px 18px -20px inset blue
    .highlight-up,.highlight-down
      position: relative
      &::after
        content: ''
        display: block
        position: absolute
        width: 100%
        height: 40px
        left: 0
.datatablepage-prep
  display: flex
  flex-direction: row
  padding-bottom: 8px
  line-height: $--table-wrapper-line-height
.datatablepage-filter-info
  flex-grow: 9999
  font-size: 12px
  color: rgba(0,0,0,0.65)
  .el-tag
    margin-right: 4px
    border-radius: 0
    border-style: dashed
.datatablepage-toolbar
  flex-grow: 99
  text-align: right
  white-space: nowrap
.datatablepage-setting
  // flex-grow: 1
  // min-width: 46px
  white-space: nowrap
  margin-right: 8px
  .setting-buttons
    text-align: right
.datatablepage-prep-hack
  color: #ccc
.datatablepage-operate
  ::v-deep
    .el-button[text-type]
      font-size: $--td-font-size
      padding: 0
</style>
