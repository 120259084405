import isFunction from 'lodash/isFunction'
import renderList from './renderList'

export function resultRender (fileId) {
  return !fileId.render ? renderList.text : isFunction(fileId.render) ? fileId.render : renderList[fileId.render.type]
}

export function formatRender (columns = []) {
  return columns.map(column => {
    return {
      ...column,
      renderFormat: resultRender(column)
    }
  })
}
