export default {
  props: {
    defaultColumnList: {
      type: Array
    },
    defaultFixedLeft: {
      type: Array,
      default: () => []
    },
    defaultFixedRight: {
      type: Array,
      default: () => []
    },
    defaultVisibleList: {
      type: Array
    }
  },
  data () {
    const columnList = this.columns.map(({ name }) => name)
    return {
      columnList: this.defaultColumnList || columnList,
      fixedLeft: this.defaultFixedLeft,
      fixedRight: this.defaultFixedRight,
      visibleList: this.defaultVisibleList || columnList,
      // 列设置展示
      showColumnFilter: false
    }
  },
  // filters: {
  //   fixedFilter (name, fixedLeft, fixedRight) {
  //     return fixedLeft.includes(name) ? 'left' : fixedRight.includes(name) ? 'right' : false
  //   }
  // },
  methods: {
    visibleFilter (name) {
      return this.visibleList.includes(name)
    },
    // 获取列配置
    getColumnFilterDate (data) {
      const { columnList, fixedLeft, fixedRight, visibleList } = data
      // Clear 现有对象列表
      this.columnList = []
      this.fixedLeft = []
      this.fixedRight = []
      this.visibleList = []

      this.$nextTick(() => {
        // Done重新赋值
        this.columnList = [...fixedLeft, ...columnList, ...fixedRight]
        this.fixedLeft = fixedLeft
        this.fixedRight = fixedRight
        this.visibleList = visibleList
        this.closeColumnFilter()
      })
    },
    closeColumnFilter () {
      this.showColumnFilter = false
    }
  }
}
