export default {
  data () {
    return {
      isCollapse: this.collapsable, // 是否展开所有的收缩行, 默认true
      privateCollapseBtn: [{
        // __isPrivateCollapseBtn: true,
        type: 'text',
        icon: 'xiala',
        iconAlign: 'right',
        label: '展开',
        showabled: this.collapsable,
        func: (button) => {
          this.isCollapse = !this.isCollapse
          this.$set(button, 'label', button.label === '展开' ? '收起' : '展开')
          this.$emit('collapseClick', this.isCollapse)
        }
      }]
    }
  },
  computed: {
    inline () {
      return this.type === 'inline'
    }
  },
  directives: {
    maxHeight: {
      inserted (el, binding, vnode) {
        if (!binding.value) {
          el.style.maxHeight = '100%'
        } else {
          const formitemEl = vnode.elm.getElementsByClassName('el-form-item')[0]
          el.style.maxHeight = formitemEl ? formitemEl.offsetHeight + 2 + 'px' : 'auto'
        }
      },
      update (el, binding, vnode) {
        if (!binding.value) {
          el.style.maxHeight = '100%'
        } else {
          const formitemEl = vnode.elm.getElementsByClassName('el-form-item')[0]
          el.style.maxHeight = formitemEl ? formitemEl.offsetHeight + 2 + 'px' : 'auto'
        }
      }
    }
  },
  methods: {
    toggleCollapseBtn (flag) {
      this.$set(this.privateCollapseBtn[0], 'showabled', flag)
    }
  }
}
