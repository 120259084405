import { render, staticRenderFns } from "./DataformCheckbox.vue?vue&type=template&id=8e86f532&scoped=true&lang=pug"
import script from "./DataformCheckbox.vue?vue&type=script&lang=js"
export * from "./DataformCheckbox.vue?vue&type=script&lang=js"
import style0 from "./DataformCheckbox.vue?vue&type=style&index=0&id=8e86f532&prod&lang=sass&scoped=true"
import style1 from "./DataformCheckbox.vue?vue&type=style&index=1&id=8e86f532&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e86f532",
  null
  
)

export default component.exports