export default [{
  path: 'system/orgManage',
  component: () => import(/* webpackChunkName: "system-base" */ '@/views/System/OrgManage'),
  meta: {
    title: '组织架构管理'
  }
}, {
  path: 'system/menuManage',
  component: () => import(/* webpackChunkName: "system-base" */ '@/views/System/MenuManage'),
  meta: {
    title: '菜单管理'
  }
}, {
  path: 'system/roleManage',
  component: () => import(/* webpackChunkName: "system-base" */ '@/views/System/RoleManage'),
  meta: {
    title: '角色管理'
  }
}, {
  path: 'system/accountManage',
  component: () => import(/* webpackChunkName: "system-base" */ '@/views/System/AccountManage'),
  meta: {
    title: '人员管理'
  }
}, {
  path: 'system/timerManage',
  component: () => import(/* webpackChunkName: "system-base" */ '@/views/System/TimerManage'),
  meta: {
    title: '定时器管理'
  }
}]
