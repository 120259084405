import usewaterManage from './usewaterManage.js'
import customerManage from './customerManage.js'

export default [
  ...customerManage,
  ...usewaterManage,
  {
    name: 'usenatureManage',
    path: 'file/usenatureManage',
    component: () => import('@/views/FileManage/UsenatureManage'),
    meta: {
      title: '用水性质管理'
    }
  },
  {
    name: 'priceManage',
    path: 'file/priceManage',
    component: () => import('@/views/FileManage/PriceManage'),
    meta: {
      title: '价格方案管理'
    }
  },
  {
    name: 'merchantManage',
    path: 'file/merchantManage',
    component: () => import('@/views/FileManage/MerchantManage'),
    meta: {
      title: '收款账户管理'
    }
  },
  {
    name: 'usewaterAnalyse',
    path: 'file/usewaterAnalyse',
    component: () => import('@/views/FileManage/UsewaterAnalyse'),
    meta: {
      title: '用水分析配置'
    }
  }
]
