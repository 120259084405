const state = {
  isCollapse: false,
  width: '184px',
  // 授权的菜单列表
  authMenu: [],
  // 当前身份ID
  currentIdentityId: '',
  // 用户存在多个身份信息，登陆后进行存储
  identifications: []
}
const mutations = {
  TOGGLE_COLLAPSE (state, status) {
    state.isCollapse = status
  },
  TOGGLE_WIDTH (state, width) {
    state.width = width
  },
  // 初始化菜单
  INIT_MENU (state, menus) {
    state.authMenu = menus
  },
  // 插入菜单
  INSERT_MENU (state, { menus, parentData, parendKey = 'id', childKey = 'childrens' }) {
    if (menus instanceof Array) {
      state.authMenu = [...state.authMenu, ...menus]
    } else {
      if (parentData) {
        const target = state.authMenu.find(menu => menu[parendKey] === parentData)
        target[childKey] = target[childKey].push(menus)
      } else {
        state.authMenu.push(menus)
      }
    }
  },
  SET_CURRENT_IDENTITY_ID (state, id) {
    state.currentIdentityId = id
  },
  SET_IDENTIFICATIONS (state, options) {
    state.identifications = options
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
