<template lang="pug">
div.upload_content
  div(ref="file" v-viewer)
    //- div(
    //-   v-for="file in fileList"
    //-   :key="file.id")
    //-   ShowFileList(
    //-     v-bind="$attrs"
    //-     :key="`file_${file.id}`"
    //-     :file="file"
    //-     :uploadType="uploadType"
    //-     @handleRemove="handleRemove"
    //-     @handlePreview="handlePreview")
    el-upload.form-upload(
      v-bind.sync="limitParams"
      v-loading="btnLoading"
      action=""
      :class="uploadType"
      :file-list="fileList"
      :auto-upload="false"
      :list-type='listType'
      :drag='isDrag'
      :on-change="handlechange"
      :on-exceed="handleExceed")
      el-button.el-icon-upload2(v-if="isButton") 点击上传
      i.el-icon-plus(v-if="isPicture")
      div.dragBox(v-if="isDrag")
        i.el-icon-upload
        div.el-upload__text 点击或将文件拖拽到这里上传
        span.drag-tip {{tip}}
      div.el-upload__tip(v-if="!isDrag" slot="tip") {{tip}}
      div(
        slot="file"
        slot-scope="{file}"
        :key="`file_${file.uid}`")
        ShowFileList(
          v-bind="$attrs"
          :key="file.uid"
          :file="file"
          :uploadType="uploadType"
          @handleRemove="handleRemove"
          @handlePreview="handlePreview")
</template>

<script>
/**
 * 上传`
 * @props props参数
 * @prop {String} uploadType 下载展示效果类型，button\drag\picture
 * @prop {String} tip 下载备注文字
**/
import ShowFileList from './ShowFileList'
import limitMixin from './limitMixin.js'

export default {
  name: 'DataformUpload',
  mixins: [limitMixin],
  components: {
    ShowFileList
  },
  props: {
    value: [Array, Object],
    uploadType: {
      type: String,
      default: 'picture'
    },
    tip: {
      type: String,
      default: ''
    },
    resource: { // 上传接口
      type: String,
      default: '/upload'
    },
    resType: {
      type: String,
      default: 'EVENT'
    }
  },
  data () {
    return {
      currentImage: {},
      fileList: [],
      imgType: ['jpg', 'png', 'bmp', 'gif', 'tiff', 'svg', 'JPG', 'PNG', 'BMP', 'GIF', 'TIFF', 'jpeg', 'tif'],
      btnLoading: false
    }
  },
  computed: {
    isButton () {
      // 下载展示类型为button
      return this.uploadType === 'button'
    },
    isDrag () {
      // 下载展示类型为drag
      return this.uploadType === 'drag'
    },
    isPicture () {
      // 下载展示类型为picture
      return this.uploadType === 'picture'
    },
    listType () {
      // 根据下载展示类型来匹配文件列表的类型
      if (this.isButton) return 'text'
      else if (this.isDrag) return 'picture-card'
      else return 'picture-card'
    },
    postResource () {
      return `${this.resource}?resType=${this.resType}`
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (file) {
        if (!file || !file.length) { this.fileList = [] }
        // 如果传值进来是对象数组才更新fileList
        if (file && file[0] && typeof file[0] === 'object') {
          const fileList = this.value.map(item => {
            return {
              code: 0,
              data: item,
              id: item.id
            }
          })
          this.fileList = this.formatFiles(fileList)
          const ids = this.fileList.map(file => file.id)
          this.$nextTick(() => this.$emit('input', ids))
        }
      }
    }
  },
  methods: {
    handlechange (file, fileList) {
      // 是否通过限制检验
      const passLimit = this.isPasslimit(file, fileList)
      passLimit && this.postFile(file.raw)
    },
    /**
     * 上传接口
     * @param {*} file 单个上传的文件
     */
    postFile (file) { // 上传接口
      this.btnLoading = true
      // 定义请求体
      const data = new FormData()
      data.append('files', file, file.name) // 添加到请求体
      this.$post({
        url: this.postResource,
        data
      }).then(res => {
        this.btnLoading = false
        if (!res || !res.data) return
        res.data.data[0].uid = file.uid
        const showFiles = this.formatFiles(res.data.data)
        this.fileList = this.fileList.concat(showFiles)
        this.$emit('input', this.fileList.map(file => file.id))
      }).catch(err => {
        console.log(err)
        this.btnLoading = false
      })
    },
    handleRemove (file) {
      this.fileList = this.fileList.filter(item => item.id !== file.id)
      this.$emit('input', this.fileList.map(file => file.id))
    },
    handlePreview (image) {
      this.currentImage = image // 获取当前点击附件对象内容
      if (this.imgType.includes(image.fileType)) {
        this.$refs.file.$viewer.show()
      }
    },
    /** 转换文件列表格式 */
    formatFiles (files = []) {
      return files
        .filter(i => i.code === 0)
        .map(file => {
          const { shortUrl, fileType, fileName } = file.data
          return {
            uid: file.uid || file.id,
            id: file.id,
            fileType: fileType,
            fileName: fileName,
            url: `${this.$apiURL.fileDownload}/${shortUrl}`
          }
        })
    }
  }
}
</script>
<style lang="sass" scoped>
.form-upload
  .dragBox
    width: 100%
    height: 100%
    font-size: 0
    position: relative
    .drag-tip
      line-height: 25px
      position: absolute
      font-size: 12px
      left: 0
      right: 0
      margin: auto
      bottom: 15%
      color: #a9a6a6
.picture, .drag, .form-upload
  .el-upload__tip
    font-size: 14px
</style>
<style scoped>
.upload_content >>> .el-dialog {
  width: 80%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto !important;
  height: 90%
}
.upload_content >>> .el-dialog__body {
  height: 94%
}
.dragBox >>> .el-upload__text {
  line-height: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 35%
}
.form-upload >>> .el-upload__tip {
  margin-top: 12px;
  line-height: 16px;
}
.picture >>> .el-upload__tip {
  line-height: 16px;
  margin-top: -8px;
}
.drag >>> .el-upload__tip {
  margin-top: 33px;
}
.picture >>> .el-upload-list__item {
  width: 104px;
  height: 104px
}
.drag >>> .el-upload-list__item {
  width: 104px;
  height: 104px
}
.picture >>> .el-upload--picture-card {
  margin-bottom: 20px;
  width: 104px;
  height: 104px;
}
.picture >>> .el-icon-plus {
  font-size: 22px;
  vertical-align: top;
  margin-top: 42px;
}
.drag >>> .el-upload--picture-card {
  border: none;
  display: block;
  height: 180px;
  width: 360px;
}
.form-upload >>> .el-upload-list__item {
  margin-top: 0
}
.button >>> .el-upload-list__item:first-child {
  margin-top: 10px !important
}
.form-upload >>> .el-icon-upload2:before {
  margin-right: 10px
}
</style>
