export default [
  {
    path: 'wechatManage/userManage',
    name: 'userManage',
    component: () => import('@/views/WechatManage/UserManage'),
    meta: {
      title: '用户绑定管理'
    }
  }
]
