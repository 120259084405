<template lang="pug">
el-button(
  v-bind="button"
  v-on="$listeners"
  :disabled="$props.disabled|filterDisAble($attrs.data)"
  :ref="name"
  :type="type"
  :text-type="type==='text' ? textType : false"
  @click.prevent="onClickButton(button)")
  slot
    ButtonInfo(
      v-bind="$attrs"
      :label="button.label")
</template>

<script>
import ButtonInfo from './ButtonInfo'
import clickFunc from './clickFuncMix.js'

export default {
  inheritAttrs: false,
  name: 'ButtonItem',
  mixins: [clickFunc],
  components: {
    ButtonInfo
  },
  filters: {
    filterDisAble: (func, data) => func instanceof Function ? func(data) : func === true
  },
  props: {
    name: String,
    type: String,
    textType: String,
    disabled: {
      type: [Function, Boolean]
    }
  },
  watch: {
    $attrs (value) {
      const { loading } = this.button
      this.button = {
        loading,
        ...value
      }
    }
  },
  data () {
    return {
      button: {
        ...this.$attrs,
        loading: false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
