export default [
  {
    path: 'billManage/billQuery',
    component: () => import('@/views/BillManage/BillQuery'),
    meta: {
      title: '账单综合查询'
    }
  },
  {
    name: 'billDetail',
    path: 'billManage/billDetail/:billId',
    component: () => import('@/views/BillManage/BillQuery/children/BillDetail.vue'),
    meta: {
      title: '账单综合查询-详情'
    }
  }
]
