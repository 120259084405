<template lang="pug">
div.form-fileList
  div(v-if="uploadType !== 'button'")
    img.el-upload-list__item-thumbnail(
      :key="`img_${file.uid}`"
      :src="url"
      alt="")
    span.el-upload-list__item-actions
      span.el-upload-list__item-preview(v-if='isPreview' @click="handlePreview(file)")
        i.el-icon-zoom-in
      span.el-upload-list__item-delete(v-if='isDownload' @click="handleDownload(file)")
        i.el-icon-download
      span.el-upload-list__item-delete(@click="handleRemove(file)")
        i.el-icon-delete
      span.file-name {{file.name}}
  div.textList(v-else)
    a.el-upload-list__item-name
      i.el-icon-link {{file.name}}
      i.el-icon-close(@click="handleRemove(file)")
      i.el-icon-download(v-if='isDownload' @click="handleDownload(file)") 下载
      i.el-icon-zoom-in(v-if='isPreview' @click="handlePreview(file)") 预览
  //- el-dialog.dialogfile(:visible.sync="dialogVisible" :append-to-body="true")
  //-   img.dialogImg(v-if="isImage(file.fileType) && uploadType !== 'button'" :src="dialogImageUrl" alt="")
  //-   iframe(v-else :src='ifarmeSrc' width='100%' height='600px' frameborder='1')
</template>

<script>
/**
 * 上传`
 * @props props参数
 * @prop {Boolean} isDownload 是否支持下载
 * @prop {Boolean} isPreview 是否支持预览
**/
export default {
  name: 'showFileList',
  props: {
    uploadType: String,
    file: {
      type: Object,
      default: () => {}
    },
    isDownload: {
      type: Boolean,
      default: false
    },
    isPreview: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      defaultUrl: require('@/assets/imgs/upload.png'),
      imgType: ['jpg', 'png', 'bmp', 'gif', 'tiff', 'svg', 'JPG', 'PNG', 'BMP', 'GIF', 'TIFF', 'jpeg', 'tif']
    }
  },
  computed: {
    url () {
      return this.isImage(this.file.fileType) ? this.file.url : this.defaultUrl
    }
  },
  methods: {
    isImage (fileType) {
      // 判断文件类型是否为image
      return this.imgType.includes(fileType)
    },
    handlePreview (file) {
      this.$emit('handlePreview', file)
    },
    handleRemove (file) {
      this.$emit('handleRemove', file)
    },
    handleDownload (file) {
      // 生成a标签，模拟click下载files
      if (this.imgType.includes(file.fileType) || file.fileType === 'pdf') {
        this.$message({
          message: 'TODO支持后端返回数据内容下载',
          type: 'warning'
        })
        return
      }
      event.stopPropagation()
      event.preventDefault()
      const a = document.createElement('a')
      a.href = file.url
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
}
</script>
<style lang="sass" scoped>
.textList
  .el-upload-list__item-name [class^=el-icon]
    line-height: 2
    cursor: pointer
.dialogImg
  width: 100%
  .el-upload-list__item-actions
    font-size: 16px
</style>
<style scoped>
.form-fileList >>> .el-icon-close {
  vertical-align: top;
  top: 0;
}
.textList >>> .el-icon-download, .el-icon-zoom-in {
  float: right
}
.textList >>> .el-icon-download:before, .el-icon-zoom-in:before {
  margin-right: 5px
}
.form-fileList >>> .el-icon-link:before{
  margin-right: 4px
}
.form-fileList >>> .el-upload-list__item {
  color: #000;
  font-size: 14px;
}
.form-fileList >>> .el-upload-list__item-name [class^=el-icon] {
  font-size: 12px;
  color: #000;
  padding-left: 0
}
.form-fileList >>> .el-upload-list__item-name {
  padding-left: 0
}
.dialogfile >>> .el-icon-close {
  display: block !important
}
.form-fileList >>> .el-upload-list__item-actions {
  font-size: 16px
}
.form-fileList >>> .file-name {
  margin: 0;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90%;
  white-space: nowrap;
  line-height: 34px;
  left: 5px;
  right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}
</style>
