export default [
  {
    path: 'meterRead/meterReadingCheck',
    component: () => import('@/views/MeterReadManage/MeterReadingCheck'),
    meta: {
      title: '计费开账'
    }
  },
  {
    path: 'meterRead/meterReadingCheck/recordCheck',
    name: 'recordCheck',
    component: () => import('@/views/MeterReadManage/MeterReadingCheck/children/RecordCheck.vue'),
    meta: {
      title: '计费开账-审核'
    }
  }
]
