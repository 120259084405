<template lang="pug">
el-select(
  :key="keyName"
  v-bind="$attrs"
  v-on="$listeners"
  v-model="currentValue"
  :remote="isRemote"
  :remote-method="remoteMethod"
  :loading="searchLoading"
  :filterable="isFilter"
  :filter-method="filterMethod"
  @visible-change="visibleChange")
  el-option-group(
    v-for="group in defaultGroup"
    :key="group.label"
    :disabled="group.disabled"
    :label="group.label")
    el-option.option_Group_item(
      v-for="(option, index) in group.options"
      v-bind="option"
      :style="{ 'height': !!option.img ?  `${optionHeight}px` : '100%' }"
      :key="option.value")
      div.option
        div.optionImg(
          v-if="!!option.img"
          :style="{ height: `${optionHeight}px`, backgroundImage: `url(${option.img})` }")
        component.optionText(
          :is="isPopover(option, index) ? 'div' : 'el-popover'"
          v-bind.sync="params"
          :title="!option.subtext ? '' : option.label"
          :content="transSubtext(option, subtext, index) || option.label")
          div(slot="reference" :class="!!option.img ? 'optionText_column' : 'optionText_row'")
            span {{option.label|ellipsisSubtext(subtextlimit)}}
            span(v-if="transSubtext(option, subtext, index)") {{transSubtext(option, subtext, index)|ellipsisSubtext(subtextlimit)}}
</template>

<script>
/**
 * @module DataformSelect 选择框
 *   */
import PinyinMatch from 'pinyin-match'
import { groupBy, truncate } from 'lodash'
import { isPromise } from '@/utils/common.js'
export default {
  name: 'DataformSelect',
  /**
   *选择框
   * @props props参数
   * @prop {Array} options 传入数据
   * @prop {String} groupKey 传入数据为分组数据时，默认为group显示分组名称
   * @prop {Function} subtext 配置则输出选项说明，居右显示，支持文字，优先级高于直接在option数据中输出的subtext值
   * @prop {String} optionHeight 配置选项高度，用于配置options中有传入img的选项的高度
   * @prop {Number} subtextlimit 配置选项中，选项说明subtext可显示最长长度
   * @prop {Function} remoteFunc 配置支持远程异步搜索，配置运行请求数据函数
   * @prop {Boolean, Function} filterable Boolean表示可搜索，且搜索条件遵顼默认规则，Function表示可搜索，搜索条件遵顼传入规则
  **/
  props: {
    value: [String, Number, Array, Boolean],
    options: {
      type: Array,
      default: () => ([])
    },
    groupKey: {
      type: String,
      default: 'group'
    },
    optionHeight: {
      type: String,
      default: '90'
    },
    subtext: Function,
    subtextlimit: Number,
    remoteFunc: {
      type: Function,
      default: () => {
        return false
      }
    },
    filterable: {
      type: [Boolean, Function],
      default: true
    },
    keyName: String
  },
  data () {
    return {
      // isEllipsis: false,
      params: {
        width: '200',
        trigger: 'hover',
        placement: 'top-start'
      },
      searchLoading: false,
      remoteOptions: [],
      filterOptions: this.options
    }
  },
  filters: {
    ellipsisSubtext (val, limitValue) {
      if (!val || !limitValue) return val
      return truncate(val, { length: limitValue, separator: '...' })
    }
  },
  computed: {
    currentValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    isRemote () {
      // 如果传入函数则返回true
      if (!isPromise(this.remoteFunc())) return false
      else return true
    },
    isFilter () {
      if (this.filterable.constructor === Boolean) return this.filterable
      else return true
    },
    optionGroup () {
      return this.optionReorganiza(this.options)
    },
    defaultGroup () {
      if (this.isRemote) return this.optionReorganiza(this.remoteOptions)
      if (this.isFilter) return this.optionReorganiza(this.filterOptions)
      else return this.optionGroup
    }
  },
  methods: {
    filterMethod (query, item = this.options) {
      // 检索默认支持关键字，拼音检索
      if (!query) {
        this.filterOptions = item
        return
      }
      if (this.filterable.constructor === Boolean) {
        const data = item.filter(ones => !!PinyinMatch.match(ones.label, query))
        this.filterOptions = data
      } else this.filterOptions = this.filterable({ query, item }) // 自定义检索函数西药直接返回检索后的数据
    },
    isPopover (option, index) {
      /**
       * 是否显示浮窗
       * 判断option中label字符串长度是否小于等于传入subtextlimit值
       * 判断subtext中字符串长度是否小于等于传入subtextlimit值
       * 如果小于等于subtextlimit值，则显示true
       */
      if (!this.subtextlimit) return true
      const { label, subtext } = option
      if (!label && !subtext) return true
      const subtextVal = this.transSubtext(option, option.subtext, index)
      if (String(subtextVal).length <= this.subtextlimit && String(label).length <= this.subtextlimit) return true
      else return false
    },
    groupByOption (options) {
      const groupObj = groupBy(options, this.groupKey)
      const groups = Object.entries(groupObj).map(([label, options]) => {
        return {
          label: label === '' || label === 'undefined' ? '其他' : label,
          disabled: options.some(item => item.disabledGroup),
          options
        }
      })
      return {
        length: groups.length,
        groups
      }
    },
    transSubtext (option, customSubtext, index) {
      return typeof customSubtext === 'function'
        ? customSubtext(option, index) : option.subtext
          ? option.subtext : null
    },
    optionReorganiza (options) {
      // 获取数据重组为适应组件输出的数据
      const { length, groups } = this.groupByOption(options)
      if (length === 1) {
        return [{
          label: '',
          options: groups[0].options
        }]
      }
      return groups
    },
    remoteMethod (query) {
      this.searchLoading = true
      setTimeout(() => {
        const f = this.remoteFunc({ query })
        if (isPromise(f)) {
          f.then(data => {
            this.remoteOptions = data
            this.searchLoading = false
          })
        }
      }, 500)
    },
    visibleChange () {
      // 过滤时，如果无选值，那么下拉值重新定义为传入option值
      if (this.isFilter) this.filterOptions = this.options
    }
  }
}
</script>
<style lang="sass" scoped>
.option_Group_item
  .option
    display: flex
    flex-direction: row
    .optionImg
      width: 70px
      margin: 0 10px 0 0
      background: no-repeat center
      background-size: contain
    .optionText_column
      margin-top: 10px
      max-width: 180px
    .optionText
      width: 100%
      span
        display: block
        overflow: hidden
        text-overflow: ellipsis
</style>

<style scoped>
.option {
  max-width: 270px;
}
</style>
