import { checkActions } from '@/api/auth'
import round from 'lodash/round'
/**
* @name isPromise
* @description 判断参数是不是Promise对象
* @param {String} obj 参数
* @return {Bealoon} 是Promise对象返回true，不是则返回false
*/
export const isPromise = (obj) => {
  // 有实际含义的变量才执行方法，变量null，undefined和''空串都为false
  // 初始promise 或 promise.then返回的
  return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function'
}

/**
 * @name isElIcon
 * @description 判断参数是不是elememt的icon
 * @param {String} icon 参数
 */
export const isElIcon = (icon = '') => {
  return icon.split('-').includes('el')
}

const isType = function (type) {
  return function (obj) {
    return Object.prototype.toString.call(obj) === '[object ' + type + ']'
  }
}
export const isString = isType('String')
export const isNumber = isType('Number')
export const isArray = isType('Array')
export const isObject = isType('Object')

function toSetOptions (relations, columns) {
  columns.forEach((column, index) => {
    // 当relation配置与form里面时，options也设置在form里面
    const isFormRelation = column.form && column.form.relation
    const relation = isFormRelation ? column.form.relation : column.relation
    const name = isObject(relation) ? relation.name : relation
    const currentOptions = isFormRelation ? column.form.options : column.options
    const options = relations[name] || currentOptions || []
    if (name && options) {
      if (isFormRelation) {
        columns[index].form.options = options
      } else {
        columns[index].options = options
      }
    }
  })
}

export function renderRelationColumns (relations, columnsList) {
  columnsList.forEach(columns => {
    toSetOptions(relations, columns)
  })
}

export const composePromise = function (args) {
  const init = args.pop()
  return function (...arg) {
    return args.reverse().reduce(function (sequence, func) {
      return sequence.then(function (result) {
        return func(result, ...arg)
      })
    }, Promise.resolve(init(...arg)))
  }
}

export const classMap = {
  billPayStatus: new Map([
    ['UNPAY', 'text-danger'],
    ['PAYED', 'text-success']
  ]),
  readingStatus: new Map([
    ['UNREAD', 'text-danger'],
    ['READED', 'text-success'],
    ['BILLED', 'text-success'],
    ['NOT_BILL', 'text-info']
  ]),
  usewaterStatus: new Map([
    ['STOP', 'text-danger'],
    ['START', 'text-success']
  ]),
  processResult: new Map([
    ['NOT_PROCESS', 'text-info'],
    ['UNPROCESS', 'text-danger'],
    ['PROCESSED', 'text-success']
  ]),
  enable: new Map([
    ['STOP', 'text-danger'],
    ['START', 'text-success']
  ])
}
export function classHandleFunc (property, options) {
  const mapOptions = options || classMap[property]
  return ({ row, column }) => {
    if (column?.property === property) {
      const name = mapOptions.get(row[property])
      return name ? ['status-card', name] : []
    } else {
      return []
    }
  }
}

export function multiColumnClassHandleFunc (props = [], options) {
  return ({ row, column }) => {
    const prop = props.find(p => p === column?.property)
    if (prop) {
      const mapOptions = options ? options[prop] || classMap[prop] : classMap[prop]
      const name = mapOptions.get(row[prop])
      return name ? ['status-card', name] : []
    } else {
      return []
    }
  }
}

// 账单缴费状态样式类名处理
export const billPayStatusClassFunc = classHandleFunc('billPayStatus')
// 抄表状态样式类名处理
export const readingStatusClassFunc = classHandleFunc('readingStatus')
// 处理状态
export const processResultClassFunc = classHandleFunc('processResult')

export function isEmpty (value) {
  return value === null || value === undefined || value === '' || (value instanceof Array && !value.length) || (typeof value === 'object' && !Object.keys(value).length)
}

// 表格列的name数组，舍弃第一列，用于合计行的计算
export function getColumnKeys (columns) {
  return columns.reduce((res, column, index) => {
    if (column.innerColumns) {
      const keys = column.innerColumns.map(i => i.name)
      res = [...res, ...keys]
    } else if (index !== 0) {
      res.push(column.name)
    }
    return res
  }, [])
}

export function getPageActions (pageRights) {
  return pageRights.reduce((obj, item) => {
    obj[item.name] = checkActions(item.url)
    return obj
  }, {})
}

/**
 * 数字格式化
 * @param number 数字
 * @param precision 保留后几位
 * @returns
 */
export const amoutInterval = function (number, precision) {
  // round 是lodash-es中保留小数点后几位的方法，如若没有，可以使用tofixed
  const amout = round(number, precision || 2).toLocaleString()
  // 如果不需要保留后几位，直接返回
  if (!precision) {
    return amout
  }
  // 如果小数点后几位不符合要求，则动态+0
  if (amout.includes('.')) {
    const num = amout.length - amout.indexOf('.') - 1
    return num < precision ? `${amout}${Array(precision - num).fill(0).join('')}` : amout
  }
  // 如是整数，则动态补0
  return `${amout}.${Array(precision).fill(0).join('')}`
}

/**
 * 数字转换，将大额数字转换为万
 * @param value 数字值
 * @param precision 精度
 */
export function bigNumberTransform (value, precision, formate) {
  let result = value
  const k = 10000
  if (typeof result === 'string') {
    result = Number(value)
  }
  if (result === 0) return result
  else if (result < k) return round(Number(`0.${round(result)}`), precision || 3)
  else result = round(result / k, precision || 3)
  // return amoutInterval(result, precision || 3)
  return formate ? amoutInterval(result, precision || 3) : result
}
