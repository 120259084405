<template lang='pug'>
.form-body__container(:label-position="labelPosition")
  slot(name="bodyHeader")
  component(
    :is="bodyLayout[type] ? bodyLayout[type] : 'FormBodyDefault'"
    :ref="type"
    v-bind="$attrs"
    v-on="$listeners")
  slot(name="bodyFooter")
</template>
<script>
/** */
import FormBodyDefault from './Default'
import InlineGrid from './Grid/Inline'
import FormBodyRow from './Row'
export default {
  name: 'FormBody',
  components: {
    FormBodyDefault,
    InlineGrid,
    FormBodyRow
  },
  props: {
    type: {
      type: String,
      default: 'default'
    },
    labelPosition: {
      type: String,
      default: 'right',
      validator: (value) => {
        return ['top', 'left', 'right'].indexOf(value) > -1
      }
    }
  },
  data () {
    return {
      bodyLayout: Object.freeze({
        grid: InlineGrid,
        row: FormBodyRow,
        default: FormBodyDefault
      })
    }
  }
}
</script>

<style lang="sass" scoped>
// 配置只读表单间距，默认8px, 遵循全局配置
.form-body__container[readonly = 'true']
  ::v-deep .el-form-item
    margin-bottom: $--formItem-readOnlySpace
  ::v-deep .el-row
    margin: $--formItem-readOnlySpace 0
  ::v-deep .inline-cell
    padding: $--formItem-readOnlySpace 8px
    box-sizing: border-box
.form-body__container[label-position="top"]
  ::v-deep .el-form-item
    display: block
</style>

<style scoped>
/* 只读情况下，校验标识不显示 */
.form-body__container[readonly] >>> .el-form-item__label:after{
  display: none
}
</style>
