<template lang="pug">
component(
  :is="isGroup ? 'el-button-group' : 'div'"
  :class="isVertical ? 'vertical' : ''")
  el-badge(
    v-for="(button, index) in buttonList"
    v-if="filterShowAble(button.showabled, data)"
    v-bind="button.badge"
    :key="button.name"
    :class="[needDivider ? `divider--${dividerDirection}` : '']")
    component(
      :is="button.tooltip ? 'el-tooltip' : 'span'"
      v-bind="button.tooltip")
      span(v-if="button.tooltip",slot="content",v-html="button.tooltip.content")
      DropButtonItem(
        v-if="button.dropdownButtons"
        v-bind="button"
        :data="data"
        :formValidate="formValidate")
      ButtonItem(
        v-else
        v-bind="button"
        :data="data"
        :formValidate="formValidate")
</template>
<script>
/**
 * @module ButtonGroup 按钮组
 *   */
import ButtonInfo from './ButtonInfo'
import ButtonItem from './ButtonItem'
import DropButtonItem from './DropButtonItem'

export default {
  inheritAttrs: false,
  name: 'ButtonGroup',
  components: {
    ButtonInfo,
    ButtonItem,
    DropButtonItem
  },
  /**
   * 按钮组，Array，包含多个button单元对象
   * @example
   * [{...el-button, label: '', func: () => {}}]
   * @props props参数
   * @prop {Boolean} isGroup 是否以按钮组的方式出现
   * @prop {Boolean} isVertical 按钮组是否垂直显示（前提是isGroup为true）
   * @prop {Boolean} needDivider 按钮组是否需要分割线（TODO 适应带badge标记的按钮组）
   * @prop {Boolean} dividerDirection 分割线方向(horizontal/vertical)
   * @prop {Array} buttonList 按钮组，以多个按钮配置为对象
   * @prop {Function} formValidate 表单验证函数
   * @prop {Object} data disabled/showabled/func补充的回调参数
   **/
  props: {
    isGroup: {
      type: Boolean,
      default: false
    },
    isVertical: {
      type: Boolean,
      default: false
    },
    needDivider: {
      type: Boolean,
      default: false
    },
    dividerDirection: {
      type: String,
      default: 'vertical'
    },
    buttonList: {
      type: Array,
      default: () => ([])
    },
    formValidate: {
      type: Function
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    filterShowAble: (func, data) => func instanceof Function ? func(data) : func !== false
  }
}
</script>

<style lang="sass" scoped>
.el-badge+.el-badge
  margin-left: 8px
.el-badge+.divider--vertical
  margin-left: 0px
.el-button-group
  .el-badge+.el-badge
    margin-left: 0px
.el-button-group.vertical
  .el-badge
    display: block
.divider--vertical+.divider--vertical:before
  content: ''
  display: inline-block
  width: 1px
  height: 1em
  margin: 0 8px
  vertical-align: middle
  background-color: #d9d9d9
.divider--horizontal+.divider--horizontal:before
  content: ''
  display: block
  height: 1px
  width: 100%
  margin: 8px 0
  background-color: #d9d9d9
</style>
