import axios from 'axios'
import Vue from 'vue'
import md5 from 'md5'
import uuidv4 from 'uuid/v4'
import interceptorResponse from './interceptor'
import encryption from '@/utils/encryption'
import apiURL from './apiURL'

/*
  1.正常get/post/patch/put/delete请求封装
  2.内定请求方式：relation/download
  4.拦截器返回数据
  5.定义接口内部鉴权请求内容
*/
const API = '/api'
axios.defaults.baseURL = API
axios.defaults.headers.common['Wfbase-Access-AppId'] = 'web'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.delete['Content-Type'] = 'application/json'
// 请求超时时间
const TIMEOUT = 600000

// 签名转换
function signFormat (props) {
  return md5(Object.keys(props).map(el => (el + '=' + props[el]).toLowerCase()).sort().join('&') + localStorage.token)
}

// 请求地址
function HostPort (url) {
  const location = window.location
  const hostPort = location.protocol + '//' + location.host + '/' + API.replace(/\//g, '') + '/'
  // const hostPort = location.protocol + '//' + location.host + '/' + process.env.API + '/'
  return url ? hostPort + url : hostPort
}

// 请求头
const httpHeader = (props, timeStamp) => {
  const sign = {
    'Wfbase-Access-Token': localStorage.token,
    'Wfbase-Access-Version': '0.0.1'
  }
  return {
    ...props.headers,
    'Wfbase-Access-Sign': signFormat({
      ...sign,
      ...props.params,
      ...props.data,
      'Wfbase-Access-TimeStamp': timeStamp
    }),
    'Wfbase-Access-TimeStamp': timeStamp,
    'Wfbase-Access-Token': sign['Wfbase-Access-Token'],
    'Wfbase-Access-Version': sign['Wfbase-Access-Version'],
    'Wfbase-Access-ReqId': uuidv4()
  }
}

axios.interceptors.response.use(
  response => {
    // 拦截响应，做统一处理
    return interceptorResponse(response)
  },
  // 接口错误状态处理，也就是说无响应时的处理
  error => {
    error.response && interceptorResponse(error.response)
    return Promise.reject(error.response || error) // 返回接口返回的错误信息
  }
)

export function fetch (props) {
  const timeStamp = new Date().getTime()
  const options = {
    timeout: TIMEOUT,
    ...props,
    method: props.method,
    headers: httpHeader(props, timeStamp),
    retry: 2,
    retryDelay: 1000,
    params: {
      ...props.params,
      timeStamp: timeStamp
    }
  }
  return new Promise((resolve, reject) => {
    axios(options)
      .then(
        response => {
          if (response.data.code === 0) {
            resolve(response.data)
          }
        },
        rej => {
          console.error('is no action:', rej)
          // const message = reject && reject.message
          reject(rej || false)
        }
      )
      .catch(error => {
        console.error('is axios error', error)
        // const message = error && error.message
        reject(error || false)
      })
  })
}

/**
 * @param {Array} requests 所有请求方法
 **/
export function fetchAll (requests = []) {
  return axios.all(
    requests
  ).then((response) => {
    return response
  })
}

export const get = ({ url, params, timeout = TIMEOUT, closeErrorToast }) => {
  return fetch({
    method: 'get',
    url,
    params,
    timeout,
    closeErrorToast
  })
}

export const post = ({ url, data }) => {
  return fetch({
    method: 'post',
    url,
    data
  })
}

export const put = ({ url, data }) => {
  return fetch({
    method: 'put',
    url,
    data
  })
}

export const patch = ({ url, data }) => {
  return fetch({
    method: 'patch',
    url,
    data
  })
}

export const httpDelete = ({ url, data }) => {
  return fetch({
    method: 'delete',
    url,
    data
  })
}

export function getRelation (relations) {
  return fetch({
    method: 'get',
    url: apiURL.relation,
    params: { relationList: relations.join(',') }
  })
}
export function getRelationByFilter (relations) {
  return fetch({
    method: 'post',
    url: apiURL.relation,
    data: {
      relationList: relations
    }
  })
}

export function download ({ url, params = {} }) {
  let strParams = ''
  Object.keys(params).map((key, index) => {
    strParams += index > 0 ? `&${key}=${params[key]}` : `${key}=${params[key]}`
  })
  const link = strParams ? url + '?' + encodeURIComponent(strParams) : url
  return HostPort(link)
}

const api = {
  install (Vue, options) {
    Vue.prototype.$fetch = fetch
    Vue.prototype.$fetchAll = fetchAll
    Vue.prototype.$md5 = encryption
    Vue.prototype.$get = get
    Vue.prototype.$post = post
    Vue.prototype.$put = put
    Vue.prototype.$patch = patch
    Vue.prototype.$httpDelete = httpDelete
    Vue.prototype.$relation = getRelation
    Vue.prototype.$getRelationByFilter = getRelationByFilter
    Vue.prototype.$download = download
  }
}
Vue.use(api)

export default api
