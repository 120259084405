<template lang="pug">
span
  Icon(
    v-if="icon && showIcon && iconAlign !== 'right'"
    :icon="icon")
  span(
    v-if="label"
    :style="style"
    :class="textClass") {{label}}
  Icon.el-icon--right(
    v-if="icon && showIcon && iconAlign === 'right'"
    :icon="icon")
</template>

<script>
import { isElIcon } from '@/utils/common.js'

export default {
  inheritAttrs: false,
  name: 'ButtonInfo',
  props: {
    icon: String,
    iconAlign: String,
    label: String,
    textStyle: {
      type: Object,
      default: () => ({})
    },
    textClass: {
      type: Array
    },
    isDropdownButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    showIcon () {
      return !isElIcon(this.icon)
    },
    style () {
      if (Object.keys(this.textStyle).length) {
        return this.textStyle
      }
      return this.label && this.icon && this.iconAlign !== 'right' ? 'margin-left: 5px;' : ''
    }
  }
}
</script>

<style lang="sass" scoped>

</style>
