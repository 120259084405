import { clearAppStorage } from '@/api/auth'
import store from '@/store'
export default [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "systemBase" */ '@/views/Login/index.vue')
  },
  {
    path: '/loginout',
    redirect () {
      clearAppStorage()
      store.dispatch('tagsView/delAllViews')
      return '/login'
    }
  },
  {
    path: '/home',
    name: 'homePage',
    component: () => import('@/views/Home'),
    meta: {
      title: '主页',
      icon: ''
    }
  }
]
