class Event {
  constructor () {
    this.funsList = {}
  }

  // 添加消息监听的方法
  on (eventName, cb) {
    if (typeof eventName !== 'string') {
      throw new Error('eventName为字符串类型')
    }
    if (typeof cb !== 'function') {
      throw new Error('callback为函数类型')
    }
    if (!this.funsList[eventName]) {
      this.funsList[eventName] = []
    }
    this.funsList[eventName].push(cb)
    // 为了防止匿名函数的影响，在添加时将取消监听的方法返回
    return () => this.off(eventName, cb)
  }

  // 移除消息监听的方法
  off (eventName, cb) {
    if (typeof eventName !== 'string') {
      throw new Error('eventName为字符串类型')
    }

    if (cb && (typeof cb !== 'function')) {
      throw new Error('callback为函数类型')
    }
    if (!this.funsList[eventName]) {
      throw new Error(`不存在该${eventName}监听`)
    }

    if (!cb) { // 如果没有第二个参数，就移除所有的监听事件
      if (this.funsList[eventName]) {
        delete this.funsList[eventName]
      }
    } else {
      if (!this.funsList[eventName].includes(cb)) {
        throw new Error(`要移除的'${eventName}'事件不存在`)
      } else {
        const index = this.funsList[eventName].findIndex(item => item === cb)
        this.funsList[eventName].splice(index, 1)
        if (this.funsList[eventName].length === 0) {
          delete this.funsList[eventName]
        }
      }
    }
  }

  // 发布消息的方法
  emit (eventName, data) {
    return new Promise((resolve, reject) => {
      if (typeof eventName !== 'string') {
        reject(new Error('eventName必须是字符串类型'))
        console.error('eventName必须是字符串类型')
        // throw new Error('eventName必须是字符串类型')
      }
      if (!this.funsList[eventName]) {
        reject(new Error(`不存在名称为'${eventName}'的事件监听`))
        console.error(`不存在名称为'${eventName}'的事件监听`)
        // throw new Error(`不存在名称为'${eventName}'的事件监听`)
      } else {
        this.funsList[eventName].forEach((cb) => {
          cb.call(this, data)
        })
        resolve(true)
      }
    })
  }
}
export default Event
