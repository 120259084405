import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import systemBase from './base'
import sysytemRouter from './sysytem'
import fileRouter from './file'
import meterReadRouter from './meterRead'
import billRouter from './bill'
import reportRouter from './report'
import wechatRouter from './wechat'
// import { getToken, removeToken } from '@/api/auth'
import { getToken } from '@/api/auth'
import { routeDataFormat } from '@/utils/routeDataFormat.js'
import { Message } from 'element-ui'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    ...systemBase,
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/App/AppFrame'),
      children: [
        ...fileRouter,
        ...sysytemRouter,
        ...meterReadRouter,
        ...billRouter,
        ...reportRouter,
        ...wechatRouter,
        {
          path: '*',
          hidden: true,
          name: '404',
          component: () => import('@/views/ErrorPage/404'),
          meta: {
            title: 'not found'
          }
        }
      ]
    }
  ]
})

// const checkUser = (to, next, router) => {
// // TODO 验证用户页面使用权限
// }

router.beforeEach((to, from, next) => {
  // 每次跳转判断是否有token如果没有则跳转到登录页
  const hasToken = getToken()
  if (hasToken) {
    // 有权限
    const routerList = ['404']
    if (!routerList.includes(to.name)) {
      // 每次跳转，记录上次路由
      const routeData = routeDataFormat(from)
      store.commit('tagsView/ADD_LAST_VISITED_VIEW', routeData)
    }
    if (to.path === '/') {
      const menus = localStorage.menus && JSON.parse(localStorage.menus)
      const path = menus?.[0]?.children?.[0]?.url
      path ? router.push(path) : next()
    } else {
      store.commit('tagsView/UPDATE_VISITED_VIEW', routeDataFormat(to))
      next()
    }
  } else if (to.name === 'login') {
    // 没权限并前往登陆页面
    next()
  } else {
    Message({
      showClose: true,
      message: '请先登录',
      type: 'error'
    })
    router.push('/login')
  }
})

export default router
