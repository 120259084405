export default [
  {
    path: 'meterRead/meterReadingManage',
    component: () => import('@/views/MeterReadManage/MeterReadingManage'),
    meta: {
      title: '抄表数据查询'
    }
  },
  {
    name: 'meterReadingDetail',
    path: 'meterRead/meterReadingManage/meterReadingDetail/:recordId',
    component: () => import('@/views/MeterReadManage/MeterReadingManage/children/MeterReadingDetail.vue'),
    meta: {
      title: '抄表数据-详情'
    }
  }
]
