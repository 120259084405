// import cloneDeep from 'lodash/cloneDeep'
import store from '@/store'
import { getUserStorage } from '@/api/auth'

function getAuthMenu () {
  const authMenus = getUserStorage('menus') || store.getters.menus || []
  return deepRoutes(authMenus)
}

function deepRoutes (list, parentPath) {
  return list
    .filter(item => !item.hidden || !item.enabled)
    .map(item => {
      const { title, icon } = item.meta || {}
      const url = parentPath === '/' ? parentPath + item.url : item.url
      const hasChildPage = item.children && item.children.some(item => ['SYSTEM', 'MODULE', 'PAGE'].includes(item.menuTypeId))
      return {
        ...item,
        label: item.label || item.name || item.title || title,
        icon: item.icon || icon,
        url,
        children: hasChildPage ? deepRoutes(item.children, url) : null
      }
    })
}
const menus = () => {
  return getAuthMenu()
}

export default menus
