import ChainEvent from '../plugins/chains'
import keyBy from 'lodash/keyBy'
// import cloneDeep from 'lodash/cloneDeep'
export default {
  data () {
    return {
      formkey: new Date().getTime(),
      datas: this.value,
      // datas: cloneDeep(this.value),
      // formData: cloneDeep(this.value),
      // labelWidth: 'auto',
      formTempBtns: this.buttonList,
      fieldMap: this.formFields,
      updateRules: {}
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    formFields: {
      type: Array,
      required: true,
      default: () => ([])
    },
    /* 按钮属性 */
    buttonList: {
      type: Array,
      default: () => ([])
    },
    buttonFixed: {
      type: Boolean,
      default: false
    },
    /* 表单需要的列数，默认为1列 */
    colspanNum: {
      type: Number,
      default: 1,
      validator: (value) => {
        return value <= 24 && value > 0
      }
    },
    labelPosition: {
      type: String,
      default: 'right',
      validator: (value) => {
        return ['top', 'left', 'right'].indexOf(value) > -1
      }
    },
    labelWidth: {
      type: [String, Number],
      default: '100px'
    }
  },
  computed: {
    labelWidthVal () {
      return this.inline || this.$attrs['label-hidden'] || this.labelPosition === 'top' ? '' : this.labelWidth
    },
    isGrid () {
      return this.type === 'grid'
    },
    fieldRules () {
      const rules = this.formFields.reduce((result, current) => {
        return !current.form.rules ? result : {
          ...result,
          [current.name]: current.form.rules
        }
      }, {})
      return rules
    },
    rules () {
      return Object.assign({}, this.fieldRules, this.updateRules)
    },
    fieldColumns () {
      return keyBy(this.formFields, 'name')
    },
    chainMaps () {
      return this.formFields.reduce((res, fieldProps) => {
        const { form, name } = fieldProps
        // ...res, [name]: form.chains
        return !form.chains ? res : res.set(name, form.chains)
      }, new Map())
    },
    formData: {
      get () {
        return this.datas
      },
      set (datas) {
        this.datas = datas
        this.$emit('input', datas)
      }
    }
  },
  watch: {
    value: {
      deep: true,
      handler (data) {
        this.formData = data
      }
    },
    colspanNum (num) {
      if (num === 1) {
        this.toggleCollapseBtn()
      }
    }
  },
  methods: {
    formValidate () {
      return new Promise((resolve, reject) => {
        this.$refs[this.refname].validate((valid) => {
          valid ? resolve(this.button) : reject(valid)
        })
      })
    },
    resetForm () {
      this.$refs[this.refname].resetFields()
      // 防止element-ui的resetFields之后导致无法重新输入
      this.validateFailFields = {}
      this.$nextTick(() => {
        this.clearChains()
        Object.entries(this.formData).map(([key, value]) => {
          if (value == null) delete this.formData[key]
        })
      })
    },
    clearChains (fieldName) {
      if (!fieldName) {
        for (const chain of this.chainMaps.entries()) {
          const name = chain[0]
          if (typeof chain[1] === 'function') this.onChains(this.fieldColumns[name], this.formData[name])
        }
      } else {
        const chainFn = this.chainMaps.get(fieldName)
        if (typeof chainFn === 'function') this.onChains(this.fieldColumns[fieldName], this.formData[fieldName])
      }
    },
    onChains (field, value) {
      const fieldForm = field.form
      if (fieldForm.chains && fieldForm.chains instanceof Function) {
        fieldForm.chains({
          chains: new ChainEvent({ component: this, field }),
          values: { ...this.values, ...this.formData },
          value
        })
        this.$nextTick(() => {
          this.$emit('onChainChange', {
            field,
            values: { ...this.values, ...this.formData }
          })
        })
      }
    },
    onChangeField (field, value) {
      this.$set(this.formData, field.name, value)
      if (!value) {
        this.clearChains(field.name)
      } else {
        this.onChains(field, value)
      }
      this.$emit('onChange', {
        values: this.formData,
        field: field.name,
        value
      })
    },
    setRule (key, rules) {
      this.$set(this.updateRules, key, rules)
    }
  }
}
