<template lang="pug">
.data-info
  .data-info-item(
    v-for="field in fields"
    :key="field.name"
  )
    label.data-info-label {{ field.label }}:
    InfoRender(
      v-bind="field"
      :value="data[field.name]"
      :field="field"
      :data="data")
</template>

<script>

export default {
  name: 'DataInfo',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    fields: {
      type: Array,
      required: true,
      default: () => ([])
    }
  },
  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="sass" scoped>
.data-info
  line-height: 28px
  font-size: 14px
  .data-info-label
    margin-right: 4px
    color: #606266
</style>
