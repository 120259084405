const state = {
  // 实际可用当前用户菜单
  usefullMenus: [],
  // 用户名称
  userName: '',
  // 用户身份
  userIdentity: ''
}

const mutations = {
  SET_USER_NAME: (state, name) => {
    state.userName = name
  },
  SET_USER_IDENTITY: (state, userIdentity) => {
    state.userIdentity = userIdentity
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
