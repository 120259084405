import billQuery from './billQuery'
import chargingRecon from './chargingRecon'

export default [
  ...billQuery,
  ...chargingRecon,
  {
    path: 'billManage/billPay',
    component: () => import('@/views/BillManage/BillPay'),
    meta: {
      title: '人工缴费'
    }
  },
  {
    path: 'billManage/billArreargeQuery',
    component: () => import('@/views/BillManage/BillArreargeQuery'),
    meta: {
      title: '历史欠费查询'
    }
  },
  {
    path: 'billManage/invoiceQuery',
    component: () => import('@/views/BillManage/InvoiceQuery'),
    meta: {
      title: '开票记录查询'
    }
  }
]
