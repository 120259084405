<template lang="pug">
el-dropdown(
  v-on="$listeners"
  trigger="click"
  :hide-on-click="hideOnClick")
  ButtonItem(
    v-bind="$attrs")
  el-dropdown-menu(slot="dropdown")
    el-dropdown-item(
      v-for="(dropdownButton, index) in renderButtons"
      v-bind="dropdownButton"
      :key="dropdownButton.name"
      :ref="dropdownButton.name"
      :disabled="dropdownButton.disabled|filterDisAble($attrs.data)")
      ButtonItem(
        v-bind="dropdownButton"
        type="text"
        :data="$attrs.data"
        :formValidate="$attrs.formValidate")
</template>

<script>
import ButtonItem from './ButtonItem'
export default {
  inheritAttrs: false,
  name: 'DropButtonItem',
  components: {
    ButtonItem
  },
  props: {
    // 是否在点击菜单项后隐藏菜单
    hideOnClick: {
      type: Boolean,
      default: false
    },
    dropdownButtons: Array
  },
  filters: {
    filterDisAble: (func, data) => func instanceof Function ? func(data) : func === true
  },
  computed: {
    renderButtons () {
      return this.dropdownButtons.filter(button => this.filterShowAble(button.showabled, this.$attrs.data))
    }
  },
  data () {
    return {}
  },
  methods: {
    filterShowAble: (func, data) => func instanceof Function ? func(data) : func !== false
  }
}
</script>

<style lang="sass" scoped>
.el-dropdown-menu__item
  text-align: center
  line-height: 26px
</style>
