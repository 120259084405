<template lang="pug">
el-date-picker(
  :value-format="valueFormat"
  v-bind="$attrs"
  v-on="$listeners"
  v-model="currentValue"
  :type="dateType"
  :prefix-icon="prefixIcon"
  :clear-icon="clearIcon"
  :picker-options="{ shortcuts:shortcuts, ...pickerOptions }")
</template>

<script>
/**
 * @module DataformDate 日期选择器
 *   */
import day from 'dayjs'
import iconMixin from './iconMixin.js'
export default {
  name: 'DataformDate',
  mixins: [iconMixin],
  /**
   * 日期选择器
   * @props props参数
   * @prop {String, Array, Number, Date} value 值
   * @prop {String} dateType 日期选择器选择类型（year/month/date/dates/week/datetime/datetimerange/ daterange/monthrange）
   * @prop {Boolean, Array} shortcut 需要日期选择类型为选择范围或时间日期选择器（dateType: 'datetime'）才生效，因此dateType必须配置为['datetimerange', 'daterange', 'monthrange', 'datetime']四种类型，否则配置无效
   * @prop {Object} pickerOptions 日期选择器控制限制
  **/
  data () {
    const endofToday = day().endOf('date').valueOf()
    const today = day().valueOf()
    return {
      DEFAULT_PREFIX_ICON: 'el-icon-date',
      rangeGroup: ['datetimerange', 'daterange', 'monthrange'],
      defualtShortcut: [{
        text: '最近一周',
        onClick (picker) {
          const end = endofToday
          const start = day().subtract(1, 'week').startOf('date').valueOf()
          picker.$emit('pick', [start, end])
        }
      }, {
        text: '最近一个月',
        onClick (picker) {
          const end = endofToday
          const start = day().subtract(1, 'month').startOf('date').valueOf()
          picker.$emit('pick', [start, end])
        }
      }, {
        text: '最近三个月',
        onClick (picker) {
          const end = endofToday
          const start = day().subtract(3, 'month').startOf('date').valueOf()
          picker.$emit('pick', [start, end])
        }
      }],
      dateShortcut: [{
        text: '今天',
        onClick (picker) {
          picker.$emit('pick', today)
        }
      }, {
        text: '昨天',
        onClick (picker) {
          const date = day().subtract(1, 'day').valueOf()
          picker.$emit('pick', date)
        }
      }, {
        text: '一周前',
        onClick (picker) {
          const date = day().subtract(1, 'week').valueOf()
          picker.$emit('pick', date)
        }
      }]
    }
  },
  props: {
    value: [String, Array, Number, Date],
    dateType: {
      type: String,
      default: 'date'
    },
    shortcut: [String, Array],
    pickerOptions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    valueFormatType: {
      type: String,
      default: 'timestamp'
    }
  },
  computed: {
    currentValue: {
      get () {
        return this.formatValue(this.value)
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    valueFormat () {
      return this.dateType === 'week' ? '' : this.valueFormatType
    },
    shortcuts () {
      if (!this.shortcut) return null
      if (this.shortcut.constructor === Array) return this.shortcut
      else return this[`${this.shortcut}Shortcut`]
    }
  },
  methods: {
    formatValue (val) {
      const isMapGroup = [...this.rangeGroup, 'dates']
      if (!val) return ''
      if (this.dateType === 'week') return this.convertFormat(day(val).startOf('week').valueOf())
      if (isMapGroup.includes(this.dateType)) {
        return val.map(item => this.convertFormat(item))
      } else return this.convertFormat(val)
    },
    convertFormat (val) {
      // 转换格式
      if (!this.valueFormat || this.valueFormat === 'timestamp') {
        return day(val).valueOf()
      } else {
        return day(val).format(this.valueFormat.replaceAll('y', 'Y'))
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.el-range-editor
  ::v-deep .el-range__icon
    line-height: 25px
  ::v-deep .el-range-separator
    line-height: 25px
  ::v-deep .el-range__close-icon
    line-height: 25px
.el-date-editor[datetype='month']
  ::v-deep .el-input__icon.el-icon-date
    line-height: 25px
</style>
