export default [
  {
    path: 'file/customerManage',
    component: () => import('@/views/FileManage/CustomerManage'),
    meta: {
      title: '用户管理'
    }
  },
  {
    name: 'customerDetail',
    path: 'file/customerManage/customerDetail/:customerId',
    component: () => import('@/views/FileManage/CustomerManage/children/CustomerDetail.vue'),
    meta: {
      title: '用户管理-详情'
    }
  }
]
