import { fetch } from '@/api'
import { transformRequest } from '@/api/apiFormat'
import { cloneDeep } from 'lodash'

export default {
  props: {
    // 数据接口
    resource: {
      type: String
    },
    // 数据接口配置信息重新定义
    resourceConfig: {
      type: Function,
      default: ({ url, params }) => ({
        url,
        method: 'get',
        params
      })
    },
    // 数据接口响应
    transformResponse: {
      type: Function,
      default: res => res
    }
  },
  data () {
    return {
      // 当前表格数据对应的搜索条件
      currentFilterBy: {}
    }
  },
  methods: {
    // 获取表格数据
    getData (pageSize, pageIndex) {
      this.loading = true
      return new Promise((resolve, reject) => {
        if (this.defaultEmpty && !this.filterByArray.length) {
          resolve({
            total: 0,
            tableData: []
          })
          this.loading = false
          return
        }
        const currentFilterBy = cloneDeep({
          ...this.filterBy,
          ...this.extraFilterBy
        })
        this.currentFilterBy = currentFilterBy
        fetch({
          url: this.resource,
          params: transformRequest({
            pageSize,
            pageIndex,
            sortBy: this.sortBy,
            filterBy: currentFilterBy,
            filterFieldsObject: this.filterFieldsObject
          })
        })
          .then(this.transformResponse)
          .then((res) => {
            this.loading = false
            const { data, total } = res.data
            resolve({
              total,
              tableData: data
            })
          })
          .catch(e => {
            this.loading = false
          })
      })
    },
    // 获取表格数据
    async getTableData () {
      const { total, tableData } = await this.getData(this.pageSize, this.pageIndex)
      this.total = total
      this.tableData = tableData
      this.$emit('afterGetData', tableData)
    },
    // 获取全部数据
    async getAllTableData () {
      const { tableData } = await this.getData(999999, 1)
      this.allTableData = tableData
      this.$emit('afterGetAllData', tableData)
    }
  }
}
