<template lang='pug'>
.tree__container
  div.selectTree(
    ref="selectTree"
    v-if="isSelect"
    v-clickoutside="handleClose")
    DataformInput(
      :class="showselectTree ? 'iconSelectType' : ''"
      v-model="inputcurrentValue",v-bind="inputProps"
      @click.native="checkSelectPanel")
    el-collapse-transition()
      Tree.transition-box(
        v-show="showselectTree"
        v-bind="$attrs"
        v-model="currentValue"
        v-on="$listeners"
        :options="options"
        :valueKey="valueKey")
  Tree(
    ref="tree"
    v-else
    v-bind="$attrs"
    v-model="currentValue"
    v-on="$listeners"
    :options="options"
    :valueKey="valueKey")
</template>

<script>
import Tree from './tree'
const clickoutside = {
  bind (el, binding, vnode) { // 初始化指令
    function documentHandler (e) {
      if (el.contains(e.target)) return false // 这里判断点击的元素是否是本身，是本身，则返回
      // 判断指令中是否绑定了函数
      if (binding.expression) binding.value(e) // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__treeClickOutside__ = documentHandler
    document.addEventListener('click', documentHandler)
  },
  unbind (el, binding) { // 解除事件监听
    document.removeEventListener('click', el.__treeClickOutside__)
    delete el.__treeClickOutside__
  }
}
export default {
  name: 'DataformTree',
  components: {
    Tree
  },
  props: {
    /**
     * @prop {Array} value 树形选择器选项的值
     * @prop {Boolean} isSelect 是否弹窗展示
     */
    value: Array,
    isSelect: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => ([])
    },
    valueKey: {
      type: String,
      default: 'value'
    }
  },
  computed: {
    currentValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    inputcurrentValue: {
      get () {
        if (!this.value) return ''
        return this.filterDataLabel(this.value).join(', ')
      }
    }
  },
  data () {
    return {
      // inputcurrentValue: this.value.join(','),
      inputProps: {
        icon: 'arrow-down',
        iconAlign: 'right',
        readonly: true
      },
      showselectTree: false
    }
  },
  directives: { clickoutside }, // 自定义书组件下拉点击组件外部事件
  methods: {
    filterDataLabel (value) {
      return value.map(item => {
        return this.options.find(optionItem => optionItem[this.valueKey] === item).label
      })
    },
    checkSelectPanel () {
      this.showselectTree = !this.showselectTree
    },
    handleClose () {
      this.showselectTree = false
    }
  }
}
</script>

<style lang='sass' scoped>
.tree__container
  .el-tree
    padding: 5px
    border: 1px solid #D9D9D9
  ::v-deep .iconSelectType
    .el-input__suffix
      transform: rotateX(-180deg)
    .el-input__inner
      border-color: #1890FF
.selectTree
  position: relative
  .transition-box
    border: none
    position: absolute
    width: 100%
    padding: 10px !important
    z-index: 999
    margin-top: 5px
    background-color: #FFF
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 10%)
</style>
