export default [
  {
    name: 'OrgStatReport',
    path: 'reportManage/orgStatReport',
    component: () => import('@/views/ReportManage/OrgStatReport.vue'),
    meta: {
      title: '分水厂统计'
    }
  },
  {
    name: 'SepProStatReport',
    path: 'reportManage/sepProStatReport',
    component: () => import('@/views/ReportManage/SepProStatReport.vue'),
    meta: {
      title: '应收水费分项'
    }
  },
  {
    name: 'SummaryCurrentReport',
    path: 'reportManage/summaryCurrentReport',
    component: () => import('@/views/ReportManage/SummaryCurrentReport.vue'),
    meta: {
      title: '当期应收汇总'
    }
  }
]
