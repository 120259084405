<template lang="pug">
el-dialog.dialog-box(
  :close-on-click-modal="false"
  :visible.sync="dialogVisible")
  div.dialog-header(slot="title")
    div.dialog-header-box
      el-checkbox(
        :disabled="isSearch"
        v-model="isAll")
      span.dialog-header-text 列设置
      DataformInput(
        v-model="filterValue"
        v-bind.sync="filterConfig"
        :clearable="true"
        @input="change(filterValue)")
  div.dialog-footer(slot="footer")
    ButtonGroup(:buttonList="buttonList")
  .columnFilter
    .columnGroup(
      v-for="(item, itemIndex) in filterGroup"
      :id="`${item.type}Group`"
      :key="itemIndex")
      p.columnGroupTitle {{item.text}}
      ul.columnGroupList(:class="`${item.type}`")
        li(
          v-for="column, columnIndex in item.value"
          :key="columnIndex")
          div.columnGroupText
            Icon.icon(icon="tuodong")
            //- i.icon.iconfont.icon-tuodong
            el-checkbox(
              v-model="column.isShow"
              @change="check(column, columnIndex, item.type)")
            span.column-name {{column.name|randerLable(columns)}}
            el-tooltip.columnTooltip(
              v-for="(operate, operateIndex) in operateList"
              :key="operateIndex"
              v-if="operate.isVisible.includes(item.type)"
              :content="operate.text" placement="top")
              i(@click="operateHandle({text: operate.text, column, columnIndex, type: item.type})")
                Icon(:icon="operate.icon")
</template>

<script>
import { cloneDeep, debounce, remove, findIndex, difference } from 'lodash'
import Sortable, { MultiDrag } from 'sortablejs'
import { AutoScroll } from 'sortablejs/modular/sortable.core.esm.js'
Sortable.mount(new MultiDrag(), new AutoScroll())

export default {
  name: 'columnFilter',
  props: {
    value: Boolean,
    columnList: {
      type: Array,
      default: () => []
    },
    fixedLeft: {
      type: Array,
      default: () => []
    },
    fixedRight: {
      type: Array,
      default: () => []
    },
    visibleList: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  data () {
    const centerGroup = difference(this.columnList, [...this.fixedLeft, ...this.fixedRight])
    const left = this.defaultConfigure(this.fixedLeft, 'left')
    const right = this.defaultConfigure(this.fixedRight, 'right')
    const center = this.defaultConfigure(centerGroup, 'center')
    const operate = [
      /**
       * 操作按钮数据的展示列
       * 如有修改数据，请相关修改operateHandle函数相关数据
       */
      { isVisible: ['center', 'left'], text: '固定在右侧', icon: 'gudingzaiyouce' },
      { isVisible: ['center', 'right'], text: '固定在左侧', icon: 'gudingzaizuoce' },
      { isVisible: ['left', 'right'], text: '不固定', icon: 'buguding' }
    ]
    return {
      filterValue: '',
      filterConfig: {
        placeholder: '请输入字段名称',
        icon: 'search',
        iconAlign: 'right'
      },
      operateList: operate,
      buttonList: [{
        label: '确定',
        type: 'primary',
        func: () => {
          const visibleList = [...this.leftDeepRecord, ...this.centerDeepRecord, ...this.rightDeepRecord].filter(item => item.isShow).map(item => item.name)
          const fixedLeft = this.leftDeepRecord.map(item => item.name)
          const fixedRight = this.rightDeepRecord.map(item => item.name)
          const columnList = this.centerDeepRecord.map(item => item.name)
          if (!visibleList.length) {
            this.$message.warning('请至少勾选一个参数列')
            return
          }
          this.$emit('columnDate', { visibleList, fixedLeft, fixedRight, columnList })
          this.$emit('close')
        }
      }, {
        label: '重置',
        func: () => {
          this.reset({ left, right, center })
        }
      }, {
        label: '取消',
        func: () => {
          this.$emit('close')
        }
      }],
      filterTextGroup: ['left', 'center', 'right'],
      leftRecord: cloneDeep(left),
      centerRecord: cloneDeep(center),
      rightRecord: cloneDeep(right),
      // 深克隆数据
      leftDeepRecord: cloneDeep(left),
      centerDeepRecord: cloneDeep(center),
      rightDeepRecord: cloneDeep(right),
      // 定义Sortable实例
      columnGroup: null
    }
  },
  filters: {
    randerLable (name, columns) {
      const itemColumns = columns.find(item => item.name === name)
      return itemColumns ? itemColumns.label : name
    }
  },
  computed: {
    filterGroup () {
      return [
        { type: 'left', value: cloneDeep(this.leftRecord), text: '固定在左侧' },
        { type: 'center', value: cloneDeep(this.centerRecord), text: '不固定' },
        { type: 'right', value: cloneDeep(this.rightRecord), text: '固定在右侧' }
      ]
    },
    isSearch () { // 是否在搜索中
      return !!this.filterValue && !!this.filterValue.length
    },
    isAll: { // 对全选列设置按钮显示与否的控制
      get () {
        return ![...this.leftDeepRecord, ...this.centerDeepRecord, ...this.rightDeepRecord].some(item => !item.isShow)
      },
      set (val) {
        this.checkAll(val)
      }
    },
    dialogVisible: {
      get () {
        return this.value
      },
      set () {
        this.$emit('close')
      }
    }
  },
  methods: {
    init () {
      this.$nextTick(() => {
        this.initColumnFilter()
      })
    },
    defaultConfigure (list, align = null) {
      // 转化原始配置
      return list.map((item, index) => {
        const isShow = this.visibleList.some(isShow => isShow === item)
        return {
          name: item,
          align: align,
          isShow: isShow
        }
      })
    },
    initColumnFilter () {
      this.filterTextGroup.map(item => {
        const columnGroup = document.querySelector(`#${item}Group ul`)
        this.columnGroup = Sortable.create(columnGroup, {
          group: { name: 'columnGroup', pull: true, put: true },
          // 拖拽的时候是否阻止事件冒泡
          dragoverBubble: true,
          easing: 'cubic-bezier(1, 0, 0, 1)',
          onEnd: (evt) => this.onDropEnd(evt)
        })
      })
    },
    onDropEnd (evt) {
      const { from, to, newIndex, oldIndex } = evt
      const strFrom = from.classList[1]
      const strTo = to.classList[1]
      const dragData = remove(this[`${strFrom}Record`], (data, index) => { return index === oldIndex })
      this[`${strTo}Record`].splice(newIndex, 0, { ...dragData[0], align: strTo })

      let fromList = this[`${strFrom}Record`]
      let toList = this[`${strTo}Record`]

      // deepClone，否则拖拽会变回去
      fromList = cloneDeep(fromList)
      toList = cloneDeep(toList)

      // Clear 现有对象列表
      this[`${strFrom}Record`] = []
      this[`${strTo}Record`] = []

      this.$nextTick(() => {
        // Done
        this[`${strFrom}Record`] = fromList
        this[`${strTo}Record`] = toList
        this.changeValue({ changeData: dragData[0], from: strFrom, to: strTo })
      })
    },
    checkAll (value) { // 全选
      this.filterTextGroup.map(listName => {
        const groupValue = this[`${listName}Record`].map(item => { return { ...item, isShow: value } })
        this.$set(this, [`${listName}Record`], groupValue)
        // 深克隆数据勾选
        const deepGroupValue = this[`${listName}DeepRecord`].map(item => { return { ...item, isShow: value } })
        this.$set(this, [`${listName}DeepRecord`], deepGroupValue)
      })
    },
    check: debounce(function (column, index, type) { // 单个勾选
      // 获取勾选情况，赋值到对应数组
      this.$set(this[`${type}Record`][index], 'isShow', column.isShow)
      this.isSearch ? this.checkDeep({ checkData: column, type: type }) : this.setDeepValue()
    }),
    checkDeep (data) { // 深克隆数据的单个勾选操作
      const { checkData, type } = data
      const deepIndex = findIndex(this[`${type}DeepRecord`], (item) => { return item.name === checkData.name })
      this.$set(this[`${type}DeepRecord`][deepIndex], 'isShow', checkData.isShow)
    },
    operateHandle (data) { // 操作按钮列
      const { text, column, columnIndex, type } = data
      switch (text) {
        case '不固定': this.unfixed(column, columnIndex, type); break
        case '固定在左侧': this.fixedStart(column, columnIndex, type); break
        case '固定在右侧': this.fixedEnd(column, columnIndex, type); break
      }
    },
    unfixed: debounce(function (column, index, type) {
      // 取消固定, 首部取消放centerRecord前面，尾部取消放centerRecord后面
      const dragData = this.remove(index, type)
      const data = { ...dragData[0], align: 'center' }
      if (type === 'left') this.centerRecord.unshift(data)
      else this.centerRecord.push(data)
      this.changeValue({ changeData: dragData[0], from: type, to: 'center' })
    }),
    fixedStart: debounce(function (column, index, type) { // 固定在列首
      const dragData = this.remove(index, type)
      this.leftRecord.unshift({ ...dragData[0], align: 'left' })
      this.changeValue({ changeData: dragData[0], from: type, to: 'left' })
    }),
    fixedEnd: debounce(function (column, index, type) { // 固定在列尾
      const dragData = this.remove(index, type)
      this.rightRecord.push({ ...dragData[0], align: 'right' })
      this.changeValue({ changeData: dragData[0], from: type, to: 'right' })
    }),
    remove (columnIndex, type) {
      return remove(this[`${type}Record`], (data, index) => { return index === columnIndex })
    },
    changeValue (data) { // 判断深层记录值的变更内容
      this.isSearch ? this.setSearchDeepValue(data) : this.setDeepValue()
    },
    setSearchDeepValue (datas) { // 搜索结果深克隆数据变更
      const { changeData, from, to } = datas
      remove(this[`${from}DeepRecord`], (data, index) => { return data.name === changeData.name })
      this[`${to}DeepRecord`].unshift({ ...changeData, align: to })
      // console.log(this.leftDeepRecord, this.centerDeepRecord, this.rightDeepRecord)
    },
    setDeepValue () { // 深克隆数据变更值
      this.leftDeepRecord = cloneDeep(this.leftRecord)
      this.centerDeepRecord = cloneDeep(this.centerRecord)
      this.rightDeepRecord = cloneDeep(this.rightRecord)
    },
    change: debounce(function (val) {
      if (!val) { // 未输入搜索内容，赋值为全部数据
        this.showTextGroup()
        return
      }
      const allList = [...this.leftDeepRecord, ...this.centerDeepRecord, ...this.rightDeepRecord]
      const filterItem = allList.find(item => item.name === val)
      if (filterItem) {
        const filterAlign = filterItem.align ? filterItem.align : ''
        this.filterTextGroup.map(item => {
          if (item === filterAlign) this[`${filterAlign}Record`] = [filterItem]
          else this[`${item}Record`] = []
        })
      } else this.showTextGroup(false) // 找不到对应数据，赋值为空
    }),
    showTextGroup (isAll = true) {
      this.filterTextGroup.map(item => {
        this[`${item}Record`] = isAll ? this[`${item}DeepRecord`] : []
      })
    },
    reset (val) { // 重置展示数据，记录数据全部还原为刚刚传入时状态，如有搜索内容一并清空
      this.centerRecord = cloneDeep(val.center)
      this.rightRecord = cloneDeep(val.right)
      this.leftRecord = cloneDeep(val.left)
      this.setDeepValue()
      this.filterValue = null
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="sass" scoped>
.dialog-header-box
  display: flex
  align-items: center
  line-height: 24px
  .dialog-header-text
    margin-left: 8px
.dialog-footer
  text-align: center
.columnFilter
  width: 100%
  display: flex
  flex-direction: row
  justify-content: space-between
  .columnGroup
    flex: 1
    padding: 0 10px 0 10px
    border-right: 1px solid #efefef
    &:last-child
      border: none
    .columnGroupTitle
      margin: 8px 0 10px 20px
      color: #00000073
      font-size: 12px
    ul
      list-style: none
      padding: 0
      margin: 0
      font-size: 14px
      max-height: 260px
      min-height: 200px
      overflow-y: auto
      li
        height: 28px
        line-height: 28px
        padding: 0 10px
        overflow: hidden
        &:hover
          background: #1890ff26
          div
            .columnTooltip
              display: block
        div
          .icon
            font-size: 16px
            vertical-align: middle
            color: #b9c5d2
            line-height: 0
            height: 12px
            width: 6px
            margin-right: 8px
          .column-name
            margin-left: 8px
          .columnTooltip
            display: none
            float: right
            font-size: 18px
            vertical-align: top
            line-height: 28px
            margin-right: 5px
            color: #1890ff
</style>
<style scoped>
.dialog-header >>> .el-input {
  width: 196px;
  margin-left: 28px;
}
.dialog-header >>> .el-checkbox__label {
  font-size: 18px
}
.dialog-box >>> .el-checkbox__inner {
  width: 16px;
  height: 16px;
}
.dialog-box >>> .el-checkbox__inner::after {
  left: 5px;
  top: 2px
}
.dialog-box >>> .el-dialog__footer {
  padding: 15px 20px 15px 20px;
}
.dialog-box >>> .el-dialog__body {
  padding: 8px 15px;
  border-bottom: 1px solid #efefef;
  border-top: 1px solid #efefef;
}
</style>
