<template lang="pug">
div.file_content
  div(ref="file" :class="showType" v-viewer="{...viewerOptions}")
    div.file_box(
      v-for="image in files"
      :key="image.id")
      div.attachText(v-if="showType === 'attachText'")
        img(:src="image.src")
        span {{image.fileName}}
          i(@click="download(image)") 下载
          i(@click="onlineView(image)") 查看
      div.image_box(v-if="showType === 'image'")
        el-image.infoRender-files(
          :style="imageStyle"
          :src="image.path"
          :fit="fit")
        div.el-upload-list__item-actions
          div.shadow
          span {{image.fileName}}
          i.el-icon-zoom-in(@click="onlineView(image)")
          i.el-icon-download(@click="download(image)")
  el-dialog.dialogfile(v-if="currentImage" :visible.sync="dialogVisible" :title="dialogTitle")
</template>

<script>
import { getFileList } from './renderFile'
export default {
  name: 'InfoRenderFiles',
  props: {
    images: {
      type: Array,
      default: () => ([])
    },
    // 设置图片如何适应容器框
    fit: {
      type: String,
      default: 'contain' // fill / contain / cover / noneimage / scale-down
    },
    showType: {
      type: String,
      default: 'attachText' // image / attachText
    },
    imageStyle: {
      type: Object,
      default: () => ({
        height: '105px',
        width: '105px'
      })
    },
    viewerOptions: { // 相关配置可查看https://github.com/fengyuanchen/viewerjs#options
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    files () {
      return getFileList(this.images)
    },
    imagesList () {
      return this.files.filter(({ type }) => type === 'image').map(({ src }) => src)
    }
  },
  data () {
    return {}
  },
  methods: {
    onlineView (image) {
      this.currentImage = image // 获取当前点击附件对象内容
      if (image.type === 'image') {
        this.$refs.file.$viewer.show()
        return
      }
      this.dialogVisible = true
      this.dialogTitle = image.fileName
    },
    download (image) {
      // 生成a标签，模拟click下载files
      event.stopPropagation()
      event.preventDefault()
      const a = document.createElement('a')
      a.href = image.path
      a.download = image.fileName
      a.click()
    }
  }
}
</script>
<style lang="sass" scoped>
.image
  display: flex
  flex-direction: row
.attachText
  flex-direction: column
.file_content
  // display: flex
  ::v-deep .el-dialog
    width: 80%
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    margin: auto !important
    height: 90%
    .el-dialog__body
      height: 94%
  .file_box
    margin-right: 10px
    .attachText
      img
        display: none
      i
        cursor: pointer
        font-style: normal
        margin-left: 10px
        color: #1890FF
    .image_box
      position: relative
      &:hover
        ::v-deep .el-upload-list__item-actions
          display: flex
      .infoRender-files
        vertical-align: top
      ::v-deep .el-upload-list__item-actions
        display: flex
        align-items: center
        justify-content: center
        position: absolute
        width: 100%
        height: 100%
        top: 0
        display: none
        span
          margin: 0
          font-size: 12px
          position: absolute
          bottom: 0
          left: 0
          width: 90%
          white-space: nowrap
          line-height: 34px
          left: 5px
          right: 5px
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
          color: #FFF
        .shadow
          position: absolute
          width: 100%
          height: 100%
          top: 0
          background-color: #000
          opacity: 0.5
        i
          cursor: pointer
          padding: 10px
          font-size: 16px
          color: #FFF
          z-index: 1
</style>
