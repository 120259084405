import cloneDeep from 'lodash/cloneDeep'
import keyBy from 'lodash/keyBy'
import keys from 'lodash/keys'
import { checkEmpty } from './filterByStatic.js'

export default {
  props: {
    // 搜索栏的渲染设置
    filterFields: {
      type: Array,
      default: () => ([])
    },
    // 默认搜索条件，初始化时用
    defaultFilterBy: {
      type: Object,
      default: () => ({})
    },
    // 额外的不展示出来的搜索条件
    extraFilterBy: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      filterFormCollapsable: true,
      // 搜索条件
      filterBy: this.defaultFilterBy,
      // 搜索按钮
      filterButtonList: [
      //   {
      //   label: '重置',
      //   func: this.filterFormReset
      // },
        {
          label: '查询',
          type: 'primary',
          func: this.filterFormSubmit
        }]
    }
  },
  computed: {
    // 过滤字段的对象化，方便调取
    filterFieldsObject () {
      return keyBy(this.filterFields, 'name')
    },
    filterByArray () {
      const filterKeys = keys(this.filterBy).filter(name => this.filterFieldsObject[name].showable !== false)
      return filterKeys.filter(key => !checkEmpty(this.filterBy[key])).map(key => ({
        key,
        value: this.filterBy[key]
      }))
    }
  },
  methods: {
    // 提交搜索
    filterFormSubmit ({ formData }) {
      this.$refs.filterForm.$refs.dataform.validate()
        .then(() => {
          this.filterBy = cloneDeep(formData)
          this.watchPageChange()
        })
    },
    // 清空搜索条件
    filterFormReset () {
      this.filterBy = {}
    },
    filterFormRemove (key) {
      this.$delete(this.filterBy, key)
    }
  }
}
