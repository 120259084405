import store from '@/store'
import initMenus from '@/utils/menu'
import { get } from '@/api'
import { treeFormat } from '@/utils/tree'

export const getToken = () => {
  return localStorage.getItem('token')
}

export const setToken = (token) => {
  return localStorage.setItem('token', token)
}

export const removeToken = () => {
  return localStorage.removeItem('token')
}

// 获取用户信息
export const getUserInfo = (callback) => {
  // const loading = Loading.service({ fullscreen: true })
  return get({
    url: '/wfbase/rbac/user/get'
  }).then(res => {
    if (res) {
      const data = setUserStorage(res.data) // 将用户信息进行本地缓存
      store.commit('menu/INIT_MENU', initMenus()) // 初始化菜单
      // 将用户名称/身份缓存进vuex中
      store.commit('user/SET_USER_NAME', res.data.name)
      store.commit('user/SET_USER_IDENTITY', res.data.orgPathName)
      callback && callback(data) // 回调
    }
    return res
  })
}

export const setUserStorage = (user) => {
  const {
    id,
    jobNumber, // 工号
    name, // 姓名
    orgId, // 组织id
    orgName, // 组织名称
    dutyTypeId, // 级别id
    dutyTypeName, // 级别名称
    orgPath,
    orgPathName,
    orgTypeId, // 组织类型
    roleIds, // 角色id
    menus // 菜单
  } = user
  const authMenus = treeFormat(menus, 'id', 'parentId', 'children', false)
  const actions = menus.filter(item => item.type === 'BUTTON' || item.type === 'SUBPAGE').map(item => item.url)
  const models = [
    { name: 'userId', data: id },
    { name: 'realname', data: name },
    { name: 'dutyType', data: `${orgName}-${dutyTypeName}` },
    { name: 'fullDutyType', data: `${orgPathName}-${dutyTypeName}` },
    { name: 'RIGHTS', data: JSON.stringify({ orgId, orgPath, dutyTypeId, jobNumber, roleIds, orgTypeId }) }, // 权限
    { name: 'menus', data: JSON.stringify(authMenus) },
    { name: 'orginMenus', data: JSON.stringify(menus) },
    { name: 'roles', data: roleIds },
    // 操作权限
    { name: 'actions', data: actions }
  ]
  models.forEach(({ name, data = {} }) => {
    localStorage.setItem(name, data)
  })
  return { user, authMenus }
}

export const getUserStorage = (model) => {
  const item = localStorage.getItem(model)
  return ['RIGHTS', 'menus'].includes(model) ? JSON.parse(item) : item
}

export const clearAppStorage = () => {
  store.commit('relation/CLEAR_RELATION')
  localStorage.clear()
  sessionStorage.clear()
}

export function getActions () {
  return localStorage.actions ? localStorage.actions.split(',') : []
}

export function checkActions (action) {
  const actions = getActions()
  return actions.indexOf(action) !== -1
}
