<template lang='pug'>
.dataform-body__row
  el-row(
    v-for="(fieldRow, rowindex) in fieldGroup"
    :key="`formgroup_${rowindex}`"
    :gutter="gutter"
    :is-collapse="rowindex+1 > maxRow && collapsable")
    el-col(
      v-for="(field, colindex) in fieldRow"
      :key="colindex"
      :span="field|singleCol(defaultColspan, colspanNum, fieldRow, colindex)")
      slot(
        :name="field.name"
        :field="field"
        :data.sync="formData")
        DataformItem(
          :field="field"
          v-model="formData[field.name]"
          v-on="$listeners"
          v-bind="$attrs"
          :full="formData"
          @change="$emit('onChange', field, $event)")
</template>

<script>
/**
 * @module DataformBodyGrid
 * @description 表单内容体，栅栏格式，一般配合Dataform等form系列组件。
 */
import DataformItem from '../DataformItem'
function getFrontNum (colIndex, fieldRow, colspanNum) {
  let frontNum = 0
  for (let i = 0; i < colIndex; i++) {
    const { colSpan = 1 } = fieldRow[i].form
    frontNum += colSpan
  }
  return colspanNum - frontNum
}
export default {
  name: 'FormBodyRow',
  components: {
    DataformItem
  },
  props: {
    formFields: {
      type: Array,
      required: true,
      default: () => ([])
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    /* 表单需要的列数，默认为1列 */
    colspanNum: {
      type: Number,
      default: 1,
      validator: (value) => {
        return value <= 24 && value > 0
      }
    },
    /* 是否展开所有的收缩行, 默认true */
    collapsable: {
      type: Boolean,
      default: false
    },
    /* 设置表单行的情况 */
    maxRow: Number
  },
  data () {
    return {
      gutter: 24
    }
  },
  filters: {
    singleCol (field, defaultColspan, colspanNum, fieldRow, colIndex) {
      const { form } = field
      /**
       * 如果没有配置跨列参数，则返回：原本平均比例参数defaultColspan
       * 如果有配置参数，且配值跨列数 > 列配置数
       * 1.如果当前字段为数值第一位，则返回整行colspanNum*defaultColspan
       * 2.如果当前字段不为第一位，则减去前几位所占列数，剩下列数全部占完
       * 如果配值*平均比例参数 <= 配置总额，则返回：配值*平均比例参数
       */
      if (!form.colSpan) return defaultColspan
      else if (form.colSpan > colspanNum) {
        // 如果当前下标-1 小于0，则为首位
        const isfirstIndex = colIndex - 1 < 0
        const itemPercent = isfirstIndex ? colspanNum : getFrontNum(colIndex, fieldRow, colspanNum)
        return itemPercent * defaultColspan
      } else return defaultColspan * form.colSpan
    }
  },
  computed: {
    defaultColspan () {
      return Math.floor(this.gutter / this.colspanNum)
    },
    curRowNum () {
      const fieldLen = this.formFields.length
      return Math.ceil(fieldLen / this.colspanNum)
    },
    fieldGroup () {
      if (this.colspanNum === 1) return [this.formFields]
      else return this.setFiedGroup() // 计算输出数组
      // console.log('setFiedGroup', this.setFiedGroup())
      // return this.formFields.reduce((result, field, index) => {
      //   // const rowspan = field.form.colSpan
      //   const rowindex = Math.ceil((index + 1) / this.colspanNum) - 1
      //   console.log(result[rowindex])
      //   result[rowindex] = [].concat(result[rowindex] || [], [field])
      //   return result
      // }, [])
    }
  },
  methods: {
    setFiedGroup () {
      const result = []
      let resultItem = []
      let initNum = 0
      this.formFields.forEach((item, index) => {
        const { form } = item
        const rowspan = form.colSpan ? form.colSpan : 1
        resultItem.push(item)
        initNum += rowspan
        if (initNum >= this.colspanNum || this.formFields.length === index + 1) {
          result.push(resultItem)
          resultItem = []
          initNum = 0
        }
        return result
      })
      return result
    }
  }
}
</script>

<style lang='sass' scoped>
.el-row[is-collapse='true']
  display: none
.el-row .el-col
  padding-left: 0
  padding-right: 0
  display: inline-block
  vertical-align: top
  float: none
  // .el-form-item
  //   margin-bottom: 0!important
</style>
