<template lang='pug'>
.dataform-body__grid(:style="gridBoxStyle")
  .grid-inline-item(
    v-for="(field, rIndex) in formFields"
    :key="`field_${field.name}`"
    :is-border="border"
    :style="setSpans(field)")
    div.inline-cell(:is-required="field.form.rules|filterRequired")
      label.el-form-item__label {{columns[field.name].label}}
    div.inline-cell
      slot(
        :name="field.name"
        :field="field"
        :data.sync="formData")
      DataformItem(
        v-model="formData[field.name]"
        v-on="$listeners"
        v-bind="$attrs"
        :field="field"
        :key="field.name"
        :full="formData"
        :labelHidden="true"
        @change="$emit('onChange', field, $event)")
</template>

<script>
/** */
import uniq from 'lodash/uniq'
import DataformItem from '@/components/Dataform/DataformItem'
export default {
  name: 'InlineGrid',
  components: {
    DataformItem
  },
  props: {
    gridFlow: {
      type: String,
      default: 'row',
      validator (value) {
        return ['column', 'row'].indexOf(value) > -1
      }
    },
    border: {
      type: Boolean,
      default: true
    },
    formFields: {
      type: Array,
      default: () => ([])
    }, // 接收一个一维数组
    gridspanNum: {
      type: [Number, String],
      default: 3
    }, // 列数
    columns: {
      type: Object,
      default: () => ({})
    }, // 字段解释
    formData: {
      type: Object,
      default: () => ({})
    } // 默认表单传值
  },
  data () {
    return {}
  },
  computed: {
    gridBoxStyle () {
      const name = this.gridFlow === 'column' ? 'rows' : 'columns'
      return {
        [`grid-template-${name}`]: `repeat(${this.maxColspan}, 1fr)`,
        'grid-auto-flow': this.gridFlow
      }
    },
    maxSpans () {
      const rowValue = this.formFields.filter(item => item.form && item.form.rowSpan).map(item => item.form.rowSpan)
      const colValue = this.formFields.filter(item => item.form && item.form.colSpan).map(item => item.form.colSpan)
      return {
        row: this.findMaxNum(uniq(rowValue)),
        col: this.findMaxNum(uniq(colValue))
      }
    },
    maxColspan () {
      return this.gridspanNum >= this.maxSpans.col ? this.gridspanNum : this.maxSpans.col
    }
  },
  filters: {
    filterRequired (rules) {
      if (!rules) return null
      return rules.some(rule => rule.required)
    }
  },
  methods: {
    findMaxNum (list) {
      return list.reduce((res, num) => {
        return num >= res ? num : res
      }, 0)
    },
    setSpans (field) {
      const { rowSpan, colSpan } = field.form
      const spanSetting = {}
      if (!rowSpan && !colSpan) return ''
      if (rowSpan) {
        spanSetting['grid-row'] = `span ${rowSpan}`
      }
      if (colSpan) {
        const lableWidth = Math.abs(1 - (colSpan * 3)) // 求剩余块数占总数的几分之几
        spanSetting['grid-column'] = colSpan > this.maxColspan ? `span ${this.maxColspan}` : `span ${colSpan}`
        spanSetting['grid-template-columns'] = `1fr ${lableWidth}fr`
      }
      return spanSetting
    }
  }
}
</script>

<style lang='sass' scoped>
$border-color: #dfdfdf
$color: #666666
$bg-split: #f7f8fa
.dataform-body__grid
  display: grid
  grid-template-columns: repeat(2, 1fr)
  .grid-inline-item
    display: grid
    // grid-template-columns: minmax(100px, 1fr) minmax(100px, 2fr) // 需要加入colspans修改
    grid-template-columns: 1fr 2fr
    justify-items: center
    align-items: center
    &[is-border]
      border: 1px solid $border-color
      margin-left: -1px
      margin-top: -1px
  .inline-cell
    display: grid
    width: 100%
    height: 100%
    align-items: center
    justify-items: center
    text-align: center
    padding: $--formItem-space 8px
    box-sizing: border-box
    &:nth-child(2)
      background: $bg-split
    .el-form-item__label
      padding: 0 5px
      text-align: left
      overflow-wrap: anywhere
      line-height: 1.2
</style>
