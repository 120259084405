export default [
  {
    name: 'chargingRecon',
    path: 'billManage/chargingRecon',
    component: () => import('@/views/BillManage/ChargingRecon'),
    meta: {
      title: '银行对账'
    }
  },
  {
    name: 'chargingReconDetail',
    path: 'billManage/chargingReconDetail',
    component: () => import('@/views/BillManage/ChargingRecon/children/ChargingReconDetail.vue'),
    meta: {
      title: '对账详情'
    }
  }
]
