<template lang='pug'>
#app
  router-view
</template>

<script>
/** */
export default {
  name: 'App',
  data () {
    return {}
  }
}
</script>

<style lang='sass' scoped>

</style>
