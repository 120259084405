<template lang="pug">
.link-showType
  el-link(
    v-if="showType === 'text'"
    v-bind="$attrs"
    :disabled="disabled"
    @click="goto"
    @contextmenu.native.prevent="goto") {{linkLabel}}
    Icon(v-if="icon" :icon="icon" :class="`el-icon--${iconAlign}`")
  el-button(
    v-else-if="showType === 'button'"
    v-bind="$attrs"
    :disabled="disabled"
    @click="goto"
    @contextmenu.native.prevent="goto") {{linkLabel}}
    Icon(v-if="icon" :icon="icon" :class="`el-icon--${iconAlign}`")
  el-image(
    v-else
    v-bind="$attrs"
    :title="linkLabel"
    :class="disabled ? 'noCursor' : ''"
    @click="goto"
    @contextmenu.native.prevent="goto")
</template>
<script>
/**
 * @module DataformLink 超链接
 *   */
import replace from 'lodash/replace'
export default {
  name: 'DataformLink',
  /**
   * 超链接
   * @props props参数
   * @prop {String, Number} linkLabel 超链接文本值
   * @prop {String} url 超链接跳转链接
   * @prop {String} openType 超链接打开的方式，传入current: 在当前页面刷新跳转, new：新标签页面打开
   * @prop {String} showType 超链接展示形式，支持图片image，文本text，按钮button形式
   * @prop {String} linkType 超链接类型，支持内部链接inside，单页面锚点链接anchor, 外部链接outside
   * @prop {String} icon 图标
   * @prop {String} iconAlign 图标位置，默认显示left, 需要icon传参类型为String才有效
   **/
  props: {
    linkLabel: {
      type: [String, Number],
      required: true
    },
    url: {
      type: String,
      required: true
    },
    openType: {
      type: String,
      default: 'new'
    },
    showType: {
      type: String,
      default: 'text'
    },
    linkType: {
      type: String,
      default: 'outside'
    },
    disabled: Boolean,
    icon: String,
    iconAlign: {
      type: String,
      default: 'left'
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    goto () {
      if (this.disabled) return
      if (this.linkType === 'anchor') {
        /* 找到锚点 */
        const anchorElement = document.getElementById(replace(this.url, '#', ''))
        /* 如果对应id的锚点存在，就跳转到锚点 */
        if (anchorElement) anchorElement.scrollIntoView()
        return
      }
      if (this.openType === 'current') window.location.href = this.url // 当前页
      else window.open(this.url) // 新窗口
    }
  }
}
</script>

<style lang="sass" scoped>
.el-icon--left
  float: left
  vertical-align: top
  margin-top: 5px
.link-showType
  display: inline-block
  width: auto
  text-decoration: none
  .el-button
    .el-icon--left
      margin-top: 0
  .el-image
    cursor: pointer
    width: 300px
    height: 200px
  .noCursor
    cursor: not-allowed
</style>
