import meterReadingCheck from './meterReadingCheck'
import meterReadingManage from './meterReadingManage'

export default [
  {
    path: 'meterRead/meterReading',
    component: () => import('@/views/MeterReadManage/MeterReading'),
    meta: {
      title: '抄表录入'
    }
  },
  {
    path: 'meterRead/settleManage',
    component: () => import('@/views/MeterReadManage/SettleManage'),
    meta: {
      title: '结算管理'
    }
  },
  {
    path: 'meterRead/meterStatusManage',
    component: () => import('@/views/MeterReadManage/MeterStatusManage'),
    meta: {
      title: '表况管理'
    }
  },
  ...meterReadingManage,
  ...meterReadingCheck
]
