import DataTable from './Datatable'
import DataForm from './Dataform'
import InfoRender from './InfoRender'
import DataInfo from './DataInfo'
import ButtonGroup from './ButtonGroup'
import FormBody from './Dataform/FormBody'
import DataformGroup from './Dataform/DataformGroup'
import DataformCompared from './Dataform/DataformCompared'
import DataformSelect from './Dataform/DataformSelect'
import DataformInput from './Dataform/DataformInput'
import DataformNumber from './Dataform/DataformNumber'
import DataformNumRange from './Dataform/DataformNumRange'
import DataformRadio from './Dataform/DataformRadio'
import DataformCascader from './Dataform/DataformCascader'
import DataformCheckbox from './Dataform/DataformCheckbox'
import DataformSwitch from './Dataform/DataformSwitch'
import DataformTime from './Dataform/DataformDateTime/DataformTime'
import DataformDate from './Dataform/DataformDateTime/DataformDate'
import DataformTimeRange from './Dataform/DataformDateTime/DataformTimeRange'
import DataformSlider from './Dataform/DataformSlider'
import DataformText from './Dataform/DataformText'
import DataformUpload from './Dataform/DataformUpload'

const components = {
  DataTable,
  DataForm,
  InfoRender,
  ButtonGroup,
  FormBody,
  DataformGroup,
  DataformCompared,
  DataformSelect,
  DataformInput,
  DataformNumber,
  DataformNumRange,
  DataformRadio,
  DataformCascader,
  DataformCheckbox,
  DataformSwitch,
  DataformTime,
  DataformDate,
  DataformTimeRange,
  DataformSlider,
  DataformText,
  DataformUpload,
  DataInfo
}
export default Vue => {
  Object.keys(components).forEach(
    key => Vue.component(key, components[key])
  )
}
