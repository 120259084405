import Vue from 'vue'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import Element from 'element-ui'
import Viewer from 'v-viewer'
import Components from '../components'
import 'element-ui/lib/theme-chalk/index.css'
import 'viewerjs/dist/viewer.css'
import '@/assets/css/index.sass'

import apiURL from '@/api/apiURL'

Vue.use(Element)
Vue.use(Components)
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999 // 解决和element的dialog同时存在时的层级问题
  }
})

dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  weekdays: [
    '星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'
  ]
})
Vue.prototype.$day = dayjs
Vue.prototype.$apiURL = apiURL
