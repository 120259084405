<template lang="pug">
el-table-column(
  v-bind="columnsObject[name]"
  :key="name"
  :prop="name"
  :width="columnsObject[name].width"
  :align="columnsObject[name].align"
  :header-align="columnsObject[name].headerAlign"
  :show-overflow-tooltip="true"
  :sortable="columnsObject[name].sortable?'custom':false"
  :sort-orders="['descending', 'ascending', null]"
  :fixed="name|fixedFilter(fixedLeftList, fixedRightList)"
  :render-header="columnsObject[name].renderHeader")
  template(slot="header",slot-scope="scope")
    slot(:name="name", :column="columnsObject[name]")
      span(:title="columnsObject[name].label") {{columnsObject[name].label}}
  template(slot-scope="scope")
    span(v-if="columnsObject[name].renderFormat") {{columnsObject[name]|renderResult(scope.row)}}
    InfoRender(
      v-else
      :field="columnsObject[name]"
      :value="scope.row[scope.column.property]")
  TableColumn(
    v-if="columnsObject[name].innerColumns"
    v-for="innerColumn in columnsObject[name].innerColumns"
    :key="innerColumn.name"
    :name="innerColumn.name"
    :columns="columnsObject[name].innerColumns"
    :fixedLeft="fixedLeftList"
    :fixedRight="fixedRightList")
</template>

<script>
import keyBy from 'lodash/keyBy'
import { formatRender } from '@/components/InfoRender/render.js'

export default {
  name: 'TableColumn',
  props: {
    name: String,
    columns: Array,
    fixedLeft: {
      type: Array,
      default: () => ([])
    },
    fixedRight: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
    }
  },
  filters: {
    fixedFilter (name, fixedLeft, fixedRight) {
      return fixedLeft.includes(name) ? 'left' : fixedRight.includes(name) ? 'right' : false
    },
    renderResult (field, row) {
      return field.renderFormat(row[field.name], field, row)
    }
  },
  computed: {
    columnsObject () {
      const columnsFormt = formatRender(this.columns)
      return keyBy(columnsFormt, 'name')
    },
    fixedLeftList () {
      return this.getFullFixList(this.fixedLeft)
    },
    fixedRightList () {
      return this.getFullFixList(this.fixedRight)
    }
  },
  methods: {
    // 若当前表头是多级表头，则将当前表头下面的表头也固定
    getFullFixList (fixList) {
      return fixList.reduce((res, item) => {
        res = [...res, item]
        if (this.columnsObject[item] && this.columnsObject[item].innerColumns) {
          const innerList = this.columnsObject[item].innerColumns.map(({ name }) => name)
          res = [...res, ...innerList]
        }
        return res
      }, [])
    }
  }
}
</script>
