<template lang='pug'>
.dataform-body__default
  template(v-for="field in formFields")
    slot(
      :name="field.name"
      :field="field"
      :data.sync="formData")
      DataformItem(
        v-model="formData[field.name]"
        v-on="$listeners"
        v-bind="$attrs"
        :field="field"
        :full="formData"
        :key="field.name"
        @change="$emit('onChange', field, $event)")
</template>

<script>
/**
 * @module DataformBody
 * @description 表单内容体，普通格式，一般配合Dataform等form系列组件。
 */
import DataformItem from '../DataformItem'
export default {
  name: 'FormBodyDefault',
  components: {
    DataformItem
  },
  props: {
    formFields: {
      type: Array,
      required: true,
      default: () => ([])
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    /* 是否展开所有的收缩行, 默认true */
    collapsable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang='sass' scoped>
</style>
