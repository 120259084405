<template lang="pug">
div
  DataformInput.searchInput(
    v-if="isFilter"
    v-model="filterText"
    v-bind.sync="filterConfig")
  el-tree(
    ref='tree'
    v-bind="$attrs"
    :data="optionsResult"
    :default-checked-keys="currentValue"
    :show-checkbox="isCheck"
    :nodeKey="propsValue"
    :filter-node-method="filterMethod"
    :lazy="isLazy"
    :load="lazyLoad"
    :props="{isLeaf: leaf}"
    @check="treeChangeValue")
    template(slot-scope="{ node, data }")
      span(v-if="subtext(data)") {{subtext(data)}}
      span(v-else) {{data.label}}
</template>

<script>
import PinyinMatch from 'pinyin-match'
import uniqBy from 'lodash/uniqBy'
import { treeFormat } from '@/utils/tree.js'
import { isPromise } from '@/utils/common.js'
export default {
  name: 'tree',
  /**
   * 树形选择器
   * @props props参数
   * @prop {Array} value 树形选择器值
   * @prop {Array} options 可选项数据源(数组对象)
   * @prop {String} valueKey 选择项值的key
   * @prop {String} propsValue 作为每个树，唯一标识的属性
   * @prop {String} parentKey 父级值的key
   * @prop {Boolean} isCheck 是否存在选择勾选框
   * @prop {Boolean, Function} filterable Boolean表示可搜索，且搜索条件遵顼默认规则，Function表示可搜索，搜索条件遵顼传入规则
   * @prop {Function} remoteFunc 配置支持远程异步搜索，配置运行请求数据函数
   * @prop {String} leaf 远程异步掺入数据，改参数字段用于判断是否为最小子集
  **/
  props: {
    value: [Array],
    options: {
      type: Array,
      default: () => ([])
    },
    valueKey: {
      type: String,
      default: 'value'
    },
    propsValue: {
      type: String,
      default: 'value'
    }, // 指定选项的值为选项对象的某个属性值
    parentKey: {
      type: String,
      default: 'parentId'
    },
    isCheck: {
      type: Boolean,
      default: true
    },
    filterable: [Boolean, Function],
    remoteFunc: {
      type: Function,
      default: () => {
        return false
      }
    },
    leaf: {
      type: String,
      default: 'isPetty'
    },
    subtext: {
      type: Function,
      default: () => {
        return false
      }
    }
  },
  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    }
  },
  data () {
    return {
      currentValue: Array.from(new Set(this.value)),
      filterText: '',
      filterConfig: {
        placeholder: '输入关键字进行过滤',
        icon: 'search',
        iconAlign: 'right',
        clearable: true
      },
      remoteDatas: [], // 保存远传数据每次过来的数据，已去重
      remoteOptions: [] // 根据保存的remoteDatas返回的tree数据
    }
  },
  computed: {
    optionsResult () {
      const r = treeFormat(this.options, this.valueKey, this.parentKey, undefined, false)
      return r
    },
    defaultOptions () {
      if (!this.isRemote) return this.optionsResult
      else return this.remoteOptions
    },
    isFilter () {
      if (this.filterable.constructor === Boolean) return this.filterable
      else return true
    },
    isRemote () {
      // 如果传入函数则返回true
      if (!isPromise(this.remoteFunc())) return false
      else return true
    },
    isLazy () {
      if (!this.isRemote) return false
      else return true
    }
  },
  methods: {
    filterMethod (query, item) {
      if (!query) return true
      if (this.filterable.constructor === Boolean) return !!PinyinMatch.match(item.label, query)
      else return this.filterable({ query, item })
    },
    treeChangeValue (data, currentValue) {
      const { checkedNodes } = currentValue
      const checkedValues = checkedNodes.reduce((checkedList, item) => {
        if (!item.children) {
          checkedList.push(item[this.valueKey])
        }
        return checkedList
      }, [])
      this.$emit('input', checkedValues)
      this.$nextTick(() => {
        this.$emit('selectChange', checkedValues)
      })
    },
    lazyLoad (node, resolve) {
      if (!this.isRemote) return null
      const f = this.remoteFunc({ node })
      if (isPromise(f)) {
        f.then(data => {
          this.remoteDatas = uniqBy([...this.remoteDatas, ...data], this.propsValue)
          this.remoteOptions = treeFormat(this.remoteDatas, this.valueKey, this.parentKey, undefined, false)
          setTimeout(() => {
            resolve(data)
          }, 1000)
        })
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.searchInput
  margin-bottom: 5px
</style>
