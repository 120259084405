<template lang="pug">
div.timeRange
  component(
    :is="isFixed ? 'el-time-select' : 'el-time-picker'"
    v-bind="$attrs"
    value-format="timestamp"
    v-model="currentValueStart"
    :picker-options="pickerOptionsStart"
    :prefix-icon="prefixIcon"
    :clear-icon="clearIcon"
    :is-range="false")
  span.connect {{ connect }}
  component(
    :is="isFixed ? 'el-time-select' : 'el-time-picker'"
    v-bind="$attrs"
    value-format="timestamp"
    v-model="currentValueEnd"
    :picker-options="pickerOptionsEnd"
    :prefix-icon="prefixIcon"
    :clear-icon="clearIcon"
    :is-range="false")
</template>

<script>
/**
 * @module DataformTimeRange 时间范围选择器
 *   */
import day from 'dayjs'
import split from 'lodash/split'
import iconMixin from './iconMixin.js'
export default {
  name: 'DataformTimeRange',
  mixins: [iconMixin],
  /**
   * 时间范围选择器
   * @props props参数
   * @prop {Array} value 值
   * @prop {String} connect 连接符号
   * @prop {Boolean} isFixed 是否为固定时间选择器
   * @prop {Object} pickerOptions 时间范围选择器控制限制
  **/
  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    connect: {
      type: String,
      default: '-'
    },
    isFixed: {
      type: Boolean,
      default: false
    },
    pickerOptions: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      DEFAULT_PREFIX_ICON: 'el-icon-time'
    }
  },
  computed: {
    currentValueStart: {
      get () {
        return this.value[0]
      },
      set (val) {
        this.$emit('input', [val, this.currentValueEnd])
      }
    },
    currentValueEnd: {
      get () {
        return this.value[1]
      },
      set (val) {
        this.$emit('input', [this.currentValueStart, val])
      }
    },
    pickerOptionsStart () {
      if (this.isFixed) return this.selectConvertSize()
      else return this.pickerConvertSize()
    },
    pickerOptionsEnd () {
      if (this.isFixed) return this.selectConvertSize('end')
      else return this.pickerConvertSize('end')
    }
  },
  methods: {
    selectConvertSize (type = 'start') {
      /**
       * 限制固定时间范围大小值
       * 如果有传入minTime, maxTime，且未选择内容，则currentValueStart，currentValueEnd都不限制不可超过传入的大小值
       * 如果currentValueStart，currentValueEnd存在有值，在传入大小值前提下
       * currentValueStart限制了pickerOptionsEnd的最小值
       * currentValueEnd限制了pickerOptionsStart的最大值
      */
      const { minTime, maxTime } = this.pickerOptions
      const min = minTime || '00:00'
      if (type === 'start') {
        return {
          ...this.pickerOptions,
          minTime: min,
          maxTime: this.currentValueEnd || maxTime
        }
      } else {
        return {
          ...this.pickerOptions,
          minTime: this.currentValueStart || min,
          maxTime: maxTime
        }
      }
    },
    pickerConvertSize (type = 'start') {
      /**
       * 限制任意时间范围大小值
       * 如果有传入selectableRange，且未选择内容，则currentValueStart，currentValueEnd都不限制不可超过传入的selectableRange值
       * 如果currentValueStart，currentValueEnd存在有值，在传入大小值前提下
       * currentValueStart限制了pickerOptionsEnd的最小值
       * currentValueEnd限制了pickerOptionsStart的最大值
      */
      const { selectableRange } = this.pickerOptions
      const selecRange = split(selectableRange, ' - ', 2) // 数组格式的默认选择范围
      const defaultStart = selecRange[0] || '00:00:00'
      const defaultEnd = selecRange[1] || '23:59:59'
      if (type === 'start') {
        const currentEnd = this.currentValueEnd ? this.changeFormat(this.currentValueEnd) : defaultEnd
        const max = currentEnd || defaultEnd
        return {
          ...this.pickerOptions,
          selectableRange: `${defaultStart} - ${max}`
        }
      } else {
        const currentStart = this.currentValueStart ? this.changeFormat(this.currentValueStart) : defaultStart
        const min = currentStart || defaultStart
        return {
          ...this.pickerOptions,
          selectableRange: `${min} - ${defaultEnd}`
        }
      }
    },
    changeFormat (value, type = 'HH:mm:ss') {
      // 转换时间格式
      return day(value).format(type)
    }
  }
}
</script>

<style lang="sass" scoped>
.connect
  margin: 0 5px 0 5px
</style>

<style scoped>
.timeRange >>> .el-input {
  width: auto !important;
}
</style>
