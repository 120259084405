import files from '@/assets/imgs/upload.png'
import apiURL from '@/api/apiURL'
export const getFileURL = (shortUrl) => {
  return `${apiURL.fileDownload}/${shortUrl}`
}
// 获取文件列表
export const getFileList = (fileList = []) => {
  if (fileList === '-' || !fileList || fileList.constructor !== Array) return []
  const list = fileList.slice().sort((a, b) => a.id - b.id) // id升序
  const imgType = ['jpeg', 'jpg', 'png', 'base64', 'bmp', 'JPG', 'JPEG', 'PNG', 'BASE64', 'BMP']
  return list.length ? list.map(item => {
    // 定义文件路径
    const path = getFileURL(item.shortUrl)
    if (imgType.indexOf(item.fileType) >= 0) {
      const url = item.fileType === 'base64' ? item.base64 : path
      return {
        type: 'image',
        thumb: url,
        src: url,
        path,
        fileName: item.fileName
      }
    } else { // 附件类型
      const path = getFileURL(item.shortUrl)
      return {
        type: 'files',
        thumb: files,
        src: files,
        path,
        fileName: item.fileName,
        fileType: item.fileType
      }
    }
  }) : []
}
