<template lang='pug'>
el-form.wf-dataform__group(
  v-bind="$attrs"
  :key="formkey"
  :class="{'fixed-box': buttonFixed}"
  :ref="refname"
  :model="formData"
  :rules="rules"
  :label-width="labelWidthVal"
  :label-position="labelPosition"
  :is-fixedbtn="buttonFixed"
  :validate-on-rule-change="false")
  .wf-dataform-group__content
    .wf-dataform-group__formitem(
      v-for="(fieldgroup, index) in groupForm"
      :key="`group_form_${index}`"
      :class="`wf-dataform-group__${styleType}`")
      .form-group-label(
        :class="{'is-accordion': groupAccordion}"
        @click.prevent="toggleCollapse(index)")
        i(
          v-if="groupAccordion"
          :class="openGroups[index] ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
          style="margin-right: 8px")
        span.form-group-label__text {{groupTitle[index]}}
      .wf-dataform__body(:is-hide="!openGroups[index]" :type="groupTypeDefault[index]")
        slot(:name="`form${index}`")
          FormBody(
            ref="formBody"
            v-bind="$attrs"
            :dot="dot"
            :type="groupTypeDefault[index]"
            :labelPosition="labelPosition"
            :formData="formData"
            :columns="fieldColumns"
            :formFields="fieldgroup"
            :colspanNum="colspanNum"
            @onChange="onChangeField")
            template(#bodyHeader)
              slot(:name="`group${index}-header`")
            template(#bodyFooter)
              slot(:name="`group${index}-footer`")
  .wf-dataform-group__footer(
    :class="{'button-align__fixed': buttonFixed}")
    ButtonGroup(
      v-for="(position, index) in formBtnsKey"
      :key="position"
      :formName="`groupForm_${position}`"
      :buttonList="formButtonList[position]"
      :formValidate="formValidate")
</template>

<script>
/** */
import FormBody from './FormBody'
import ButtonGroup from '@/components/ButtonGroup'
import formCommon from './mixins/formCommon.js'
import keyBy from 'lodash/keyBy'
export default {
  name: 'DataformGroup',
  mixins: [formCommon],
  components: {
    FormBody,
    ButtonGroup
  },
  props: {
    // 字段分组
    groupSplit: {
      type: Array,
      required: true,
      default: () => ([])
    },
    // 分组类型：可选row,grid,default
    groupType: {
      type: [String, Array]
    },
    // 分组表现方式：default->默认风琴式开合；block -> 块 ； border -> 简单边框
    styleType: {
      type: String,
      default: 'default',
      validator: (value) => {
        return ['block', 'border', 'default'].indexOf(value) > -1
      }
    },
    // 分组小组标题
    groupTitle: {
      type: Array,
      required: true,
      default: () => ([]),
      validator: (titleArr) => {
        return titleArr.every(value => {
          if (typeof value === 'string') return !!value
          if (value instanceof Object) return value.icon || value.label
        })
      }
    },
    buttonFixed: {
      type: Boolean,
      default: false
    },
    buttonAlign: {
      type: String,
      default: 'right',
      validator: (value) => {
        return ['center', 'left', 'right'].indexOf(value) > -1
      }
    },
    dot: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      refname: 'dataformGroup',
      openGroups: Array.from({ length: this.groupSplit.length }, () => { return true }),
      formTempBtns: this.buttonList
    }
  },
  computed: {
    groupAccordion () {
      return this.styleType === 'default'
    },
    fieldRegister () {
      return keyBy(this.formFields, 'name')
    },
    groupForm () {
      return this.groupSplit.map(group => {
        return group.map(field => this.fieldRegister[field])
      })
    },
    groupTypeDefault () {
      return this.groupSplit.map((group, index) => {
        return this.groupType instanceof Array ? this.groupType[index] : this.groupType
      })
    },
    formButtonList () {
      // return this.buttonList.map(button => ({
      //   ...button,
      //   formData: Object.assign(this.value, this.formData)
      // }))
      return this.buttonList.reduce((res, btn) => {
        const button = {
          ...btn,
          formData: Object.assign(this.value, this.formData)
        }
        const position = btn.position || this.buttonAlign // 分组按钮组默认居右
        res[position] = [...res[position], button]
        return res
      }, { left: [], center: [], right: [] })
    },
    formBtnsKey () {
      return Object.keys(this.formButtonList)
    }
  },
  methods: {
    toggleCollapse (index) {
      if (this.styleType === 'default') {
        this.$set(this.openGroups, index, !this.openGroups[index])
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang='sass' scoped>
// -- 分组标题 start --
.fixed-box
  width: 100%
  height: 100%
  .wf-dataform-group__content
    height: calc(100% - 71px)
.wf-dataform-group__block, .wf-dataform-group__border
  border: 1px solid #e7e6e6
  margin: 10px
  .form-group-label
    padding: 0 5px
    background-color: #ffffff
    cursor: pointer
.wf-dataform-group__border
  position: relative
  margin-top: 20px
  .form-group-label
    position: absolute
    display: inline-block
    left: 13px
    top: -12px
.wf-dataform-group__block
  box-shadow: 0 1px 3px 0 rgba(0,0,0,10%)
  border-radius: 5px
  .form-group-label
    padding: 0 10px
    font-weight: bold
    font-size: 14px
    line-height: 40px
    &:not(:empty)
      border-bottom: 1px solid #e7e6e6
.form-group-label.is-accordion
  height: 50px
  line-height: 50px
  background: rgba(0,0,0,0.02)
  border: 1px solid rgba(0,0,0,0.05)
  padding: 0 1.2rem
  cursor: pointer
// -- 分组标题 end --
.wf-dataform__body
  margin: $--form-vertical-margin 24px
  &[is-hide='true']
    display: none
/* 整体group布局 start */
.wf-dataform__group[is-fixedbtn]
  // display: flex
  // flex-direction: column
  // height: 100%
  // overflow: hidden
  .wf-dataform-group__content
    flex: 1
    overflow: auto
.wf-dataform-group__footer
  display: flex
  justify-content: space-between
  padding: 0 10px
/* 整体group布局 end */
</style>
