export default [
  {
    name: 'usewaterManage',
    path: 'file/usewaterManage',
    component: () => import('@/views/FileManage/UsewaterManage'),
    meta: {
      title: '用水户管理'
    }
  },
  {
    name: 'usewaterAdd',
    path: 'file/usewaterManage/usewaterAdd',
    component: () => import('@/views/FileManage/UsewaterManage/children/UsewaterAdd.vue'),
    meta: {
      title: '用水户管理-新增'
    }
  },
  {
    name: 'usewaterEdit',
    path: 'file/usewaterManage/usewaterEdit',
    component: () => import('@/views/FileManage/UsewaterManage/children/UsewaterEdit.vue'),
    meta: {
      title: '用水户管理-编辑'
    }
  },
  {
    name: 'usewaterDetail',
    path: 'file/usewaterManage/usewaterDetail/:usewaterId',
    component: () => import('@/views/FileManage/UsewaterManage/children/UsewaterDetail.vue'),
    meta: {
      title: '用水户管理-详情'
    }
  }
]
