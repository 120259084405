import cloneDeep from 'lodash/cloneDeep'
import { filterByFormat } from '@/api/apiFormat'

export default {
  props: {
    downloadResource: {
      type: String,
      default: ''
    },
    downloadParams: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
    }
  },
  methods: {
    downloadAsync () {
      if (!this.downloadResource) return
      return new Promise((resolve, reject) => {
        // this.loading = true
        const currentFilterBy = cloneDeep({
          ...this.filterBy,
          ...this.extraFilterBy
        })
        this.$get({
          url: this.downloadResource,
          params: {
            pageSize: 999999,
            pageIndex: 1,
            filterBy: filterByFormat(currentFilterBy, this.filterFieldsObject),
            exportColumns: this.visibleList.join(','),
            ...this.downloadParams
          }
        })
          .then((res) => {
            // this.loading = false
            if (!res.data) {
              reject(res)
              return
            }
            const a = document.createElement('a')
            a.href = `${this.$apiURL.fileDownload}/${res.data.shortUrl}`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            event.stopPropagation()
            event.preventDefault()
            resolve(res)
          })
          .catch(e => {
            // this.loading = false
            reject(e)
          })
      })
    }
  }
}
