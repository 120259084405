export function routeDataFormat (route) {
  const menu = JSON.parse(localStorage.orginMenus || '[]').find(i => i.url === route.path)
  const props = ['path', 'meta', 'hash', 'query', 'params', 'fullPath']
  const routeData = {
    title: route.title || (menu && menu.name) || (route.meta.title),
    menu
  }
  for (const item of props) {
    routeData[item] = route[item]
  }
  return routeData
}
