<template lang="pug">
el-input(
  v-bind="$attrs"
  v-on="$listeners"
  v-model.trim="currentValue"
  :clearable="clearable"
  :show-password="password"
  :type="inputType")
  template(
    v-if="affixes"
    :slot="affixes.type") {{affixes.content}}
  i(
    v-if="iconDeploy"
    :slot="iconDeploy"
    :class="['el-input__icon', 'el-icon-' + icon]")
  slot(name="prepend")
  slot(name="append")
  slot(name="subComponent")
</template>
<script>
/**
 * @module DataformInput 输入框
 * @ chenpy
 *   */
export default {
  name: 'DataformInput',
  /**
   * 输入框
   * @props props参数
   * @prop {String, Number} value 输入框值
   * @prop {String} inputType 输入框类型，传入text: 文字, textarea：文本
   * @prop {Object} affixes 复合型输入框，已支持到简单内容传入
   * @prop {String} icon 带有图标标记输入类型
   * @prop {String} iconAlign 支持配置图标显示位置
   * @prop {Boolean} password 配置为密码框显示
   **/
  model: {
    prop: 'value',
    event: 'inputX'
  },
  props: {
    value: [String, Number],
    inputType: {
      type: String,
      default: 'text'
    },
    affixes: {
      type: Object,
      default: () => ({})
    },
    icon: String,
    iconAlign: {
      type: String,
      default: 'left'
    },
    password: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    currentValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('inputX', val)
      }
    },
    iconDeploy () {
      if (!this.icon) return null
      if (this.iconAlign === 'left') return 'prefix'
      else return 'suffix'
    }
  }
}
</script>

<style lang="sass" scoped>
// .el-input, .el-textarea
//   width: auto
</style>
