<script>
import DataformUpload from './DataformUpload'
// import DataformIcon from './DataformIcon'
import DataformSelect from './DataformSelect'
import DataformInput from './DataformInput'
import DataformNumber from './DataformNumber'
import DataformNumRange from './DataformNumRange'
import DataformRadio from './DataformRadio'
import DataformCascader from './DataformCascader'
import DataformCheckbox from './DataformCheckbox'
import DataformSwitch from './DataformSwitch'
import DataformTime from './DataformDateTime/DataformTime'
import DataformDate from './DataformDateTime/DataformDate'
import DataformTimeRange from './DataformDateTime/DataformTimeRange'
import ButtonGroup from '../ButtonGroup'
import DataformSlider from './DataformSlider'
import DataformText from './DataformText'
import DataformLink from './DataformLink'
import DataformTransfer from './DataformTransfer/index'
import DataformTree from './DataformTree/index'

const dataFormItem = {
  text: 'DataformText',
  input: 'DataformInput',
  number: 'DataformNumber',
  numberRange: 'DataformNumRange',
  select: 'DataformSelect',
  switch: 'DataformSwitch',
  slider: 'DataformSlider',
  checkbox: 'DataformCheckbox',
  radio: 'DataformRadio',
  cascader: 'DataformCascader',
  time: 'DataformTime',
  timeRange: 'DataformTimeRange',
  date: 'DataformDate',
  file: 'DataformUpload',
  // icon: 'DataformIcon',
  render: 'InfoRender',
  button: 'ButtonGroup',
  link: 'DataformLink',
  transfer: 'DataformTransfer',
  tree: 'DataformTree'
}

export default {
  components: {
    DataformUpload,
    // DataformIcon,
    DataformSelect,
    DataformInput,
    DataformNumber,
    DataformNumRange,
    DataformRadio,
    DataformCascader,
    DataformCheckbox,
    DataformSwitch,
    DataformTime,
    DataformTimeRange,
    DataformDate,
    ButtonGroup,
    DataformSlider,
    DataformText,
    DataformLink,
    DataformTransfer,
    DataformTree
  },
  data () {
    return {
      dataFormItem
    }
  }
}
</script>
