import InfoRenderFiles from './InfoRenderFiles'
import InfoRenderLink from './InfoRenderLink'
import { resultRender } from './render'
export default {
  name: 'InfoRender',
  components: {
    InfoRenderFiles,
    InfoRenderLink
  },
  props: {
    field: {
      type: Object
    },
    value: {
    },
    data: {
      type: Object
    }
  },
  data () {
    return {
      componentNode: Object.freeze({
        file: {
          tag: InfoRenderFiles,
          attrs: { images: this.value, ...this.field.render }
        },
        link: {
          tag: InfoRenderLink,
          attrs: { value: this.value, render: this.field.render }
        }
      })
    }
  },
  computed: {
    resultAction () {
      return resultRender(this.field)
    }
  },
  methods: {
    renderByAction () {
      return this.resultAction(this.value, this.field, this.data)
    }
  },
  created () {
    if (this.field?.render?.type === 'file') {
      this.componentNode.file.attrs.images = this.value
    }
  },
  render (h) {
    const renderTag = this.componentNode[this.field?.render?.type]
    if (renderTag) {
      const assignObj = this.field?.render?.type === 'file' ? { images: this.value } : {}
      return h(renderTag.tag, { props: Object.assign(renderTag.attrs, assignObj) })
    } else if (this.field.html) {
      return h('span', { domProps: { innerHTML: this.renderByAction() } })
    } else {
      return h('span', this.renderByAction())
    }
  }
}
